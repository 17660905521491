import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import Chart from "react-apexcharts";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  InputGroup,
  Badge,
  Dropdown,
  Popover,
} from "react-bootstrap";
import UiBadges from "../../../ui/UiBadges";
import Card from "../../../../../components/Card";
import { Link, useParams, useLocation } from "react-router-dom";
// img
import Datepickers from "../../../../../components/Datepicker";

import avatar from "../../../../../assets/images/avatar-3.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import { useDispatch, useSelector } from "react-redux";
import { getCase, getTeam } from "../../../../../actions/userAction/userAction";

const ViewDirectorEmployee = (props) => {
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const [account, setAccount] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [showDoc, setShowDoc] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  // const [teamSalary, setteamSalary] = useState(12789);
  const userList = useSelector((state) => state.userReducer.users);
  const teamList = useSelector((state) => state.teamReducer.teams);
  const casesList = useSelector((state) => state.caseReducer.casesList);

  const dispatch = useDispatch();

  const location = useLocation();
  const selectedEmployee = history.location.state.data;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowDoc = () => setShowDoc(false);

  const month = [
    { name: "January", id: 1 },
    { name: "February", id: 2 },
    { name: "March", id: 3 },
    { name: "April", id: 4 },
    { name: "May", id: 5 },
    { name: "June", id: 6 },
    { name: "July", id: 7 },
    { name: "August", id: 8 },
    { name: "September", id: 9 },
    { name: "October", id: 10 },
    { name: "November", id: 11 },
    { name: "December", id: 12 },
  ];
  let teamSalary = 0,
    totalSalary = 0,
    justification = 0,
    revenue = 0,
    justificationFact = 0;
  casesList.map((item) => (revenue = revenue + item.deal_revenue));
  if (selectedEmployee.role.name == "PNL") {
    teamList
      .filter((item) => item.id !== selectedEmployee.id)
      .map((item) => (teamSalary = teamSalary + item.salary.amount));

    totalSalary = teamSalary + selectedEmployee.salary.amount;
    justification = totalSalary * 5;
  } else {
    justification = selectedEmployee.salary.amount * 5;
  }
  justificationFact = (revenue / justification) * 100;

  useEffect(() => {
    dispatch(getCase({ userId: selectedEmployee.id }));
    dispatch(getTeam(selectedEmployee.id));
  }, []);

  console.log("iiiiii", casesList);

  const totalRevenuePopover = (
    <Popover id="popover-basic">
      <Popover.Content>Total Revenue in current month</Popover.Content>
    </Popover>
  );
  const commissionPopover = (
    <Popover id="popover-basic">
      <Popover.Content>
        And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
  );
  const unitSoldPopover = (
    <Popover id="popover-basic">
      <Popover.Content>Total Units Sold in current month</Popover.Content>
    </Popover>
  );
  const incentivePopover = (
    <Popover id="popover-basic">
      <Popover.Content>Total team incentive in current month</Popover.Content>
    </Popover>
  );
  const fixedCostPopover = (
    <Popover id="popover-basic">
      <Popover.Content>Comapny cost in current month</Popover.Content>
    </Popover>
  );
  const teamSalaryPopover = (
    <Popover id="popover-basic">
      <Popover.Content>Total team's salary</Popover.Content>
    </Popover>
  );
  const JustificationPopover = (
    <Popover id="popover-basic">
      <Popover.Content>
        And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
  );
  const totalSalaryPopover = (
    <Popover id="popover-basic">
      <Popover.Content>
        And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
  );
  const pnlPopover = (
    <Popover id="popover-basic">
      <Popover.Content>
        And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
  );

  const teamRoles = ["PNL", "T2", "T3"];

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-between">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb p-0 mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/customer">Employee</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Employee Detail View
                    </li>
                  </ol>
                </nav>
              </div>
              <Link
                to={
                  userData.role.name === "DIRECTOR"
                    ? "/director-view-employee"
                    : ""
                }
                className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2">Back</span>
              </Link>
            </div>
          </Col>
          <Col lg="12" className="mb-3 d-flex justify-content-between">
            <h4 className="font-weight-bold d-flex align-items-center">
              Employee Detail View
            </h4>
          </Col>
        </Row>

        <Row>
          <Col lg="8">
            <Card>
              <ListGroup as="ul" className="list-group-flush">
                <ListGroup.Item as="li">
                  <div>
                    <ListGroup as="ul" className="list-style-1 mb-0">
                      <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-start align-items-center"
                      >
                        <div className="avatar">
                          <img
                            className="avatar avatar-img avatar-60 rounded-circle"
                            src={avatar}
                            alt="01.jpg"
                          />
                        </div>
                        <div className="list-style-detail ml-4 mr-2">
                          <h5 className="font-weight-bold">
                            {selectedEmployee.name}
                          </h5>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <table className="table table-borderless mb-0">
                    <tbody>
                      <tr>
                        <td className="p-0">
                          <h4 className="mb-0 text-muted">Salary</h4>
                        </td>
                        <td>
                          <h4
                            style={{ float: "right" }}
                            className="mb-0 text-success d-flex justify-content-start align-items-center"
                          >
                            {selectedEmployee.salary.amount}
                          </h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <table className="table table-borderless mb-0">
                    <tbody>
                      {selectedEmployee.email && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Email ID</p>
                          </td>
                          <td>
                            <p className="mb-0 ">{selectedEmployee.email}</p>
                          </td>
                        </tr>
                      )}

                      {selectedEmployee.manager_name && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Manager Name</p>
                          </td>
                          <td>
                            <p className="mb-0 ">
                              {selectedEmployee.manager_name}
                            </p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
          <Col md="4">
            <Card className="bg-primary">
              {/* <Card.Body style="display: "> */}
              <div
                className="d-flex align-items-center"
                style={{ flexDirection: "column", padding: "20px" }}
              >
                {/* <div className="fit-icon-2 text-info text-center"> */}
                <div style={{ width: 200, height: 250, alignItems: "center" }}>
                  <CircularProgressbar
                    styles={buildStyles({
                      textColor: "#ffff",
                      textSize: "23px",
                      pathColor: `#54BB3D`,
                      trailColor: "#fff",
                    })}
                    value={justificationFact}
                    text={`${(revenue / selectedEmployee.salary.amount).toFixed(
                      1
                    )} X`}
                  />
                </div>
                {/* </div> */}
                {/* </div> */}
                <div className="ml-3">
                  <h5 className="text-white font-weight-bold">
                    {revenue.toLocaleString("en-US")}{" "}
                    <small> /{justification.toLocaleString("en-US")} AED</small>
                  </h5>
                  <strong>Justification</strong>
                </div>
              </div>
              {/* </Card.Body> */}
            </Card>
          </Col>
        </Row>
        <Row>
          {userData.role.name === "DIRECTOR" &&
            teamRoles.includes(selectedEmployee.role.name) && (
              <Col lg="12">
                <Card className="card-block card-stretch">
                  <Card.Body className="p-0">
                    <div className="d-flex justify-content-between align-items-center p-3">
                      <h5 className="font-weight-bold">Team List</h5>
                    </div>
                    <div className="table-responsive">
                      <table className="table data-table mb-0">
                        <thead className="table-color-heading">
                          <tr className="">
                            <th scope="col">S.No.</th>
                            <th scope="col">Employee Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Salary</th>
                          </tr>
                        </thead>
                        <tbody>
                          {teamList.length > 0 &&
                            teamList
                              .filter((item) => item.id !== selectedEmployee.id)
                              .map((item, index) => (
                                <tr
                                  key={item.id}
                                  className="white-space-no-wrap"
                                >
                                  <td>{index + 1}</td>
                                  <td className="">
                                    <div className="active-project-1 d-flex align-items-center mt-0 ">
                                      <div className="h-avatar is-medium">
                                        <img
                                          className="avatar rounded-circle"
                                          alt="user-icon"
                                          src={item.image || avatar}
                                        />
                                      </div>
                                      <div className="data-content">
                                        <div>
                                          <span className="font-weight-bold">
                                            {item.name}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{item.email}</td>
                                  <td>
                                    <strong>{item.salary.amount} AED</strong>
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )}
        </Row>

        <Row>
          {userData.role.name === "DIRECTOR" && (
            <Col lg="12">
              <Card className="card-block card-stretch">
                <Card.Body className="p-0">
                  <div className="d-flex">
                    <Col lg="10">
                      <div className="align-items-center p-3">
                        <h5 className="font-weight-bold">Deal List</h5>
                      </div>
                    </Col>
                    <Col lg="2">
                      <div>
                        <select
                          className="form-select form-control"
                          onSelect={() => {
                            console.log("hlos");
                          }}
                        >
                          {month.map((data) => {
                            return (
                              <option
                                value={data.id}
                                selected={new Date().getMonth() + 1 === data.id}
                              >
                                {data.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                  </div>
                  <div className="table-responsive">
                    <table className="table data-table mb-0">
                      <thead className="table-color-heading">
                        <tr className="">
                          <th scope="col">S.No.</th>
                          <th scope="col">Customer Name</th>
                          <th scope="col">Customer Email</th>
                          <th scope="col">Deal Revenue</th>
                          <th scope="col">Deal Owner</th>
                          <th scope="col">Deal Quantam</th>
                          <th scope="col" className="text-right">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {casesList.length > 0 &&
                          casesList.map((item, index) => (
                            <tr key={item.id} className="white-space-no-wrap">
                              <td>{index + 1}</td>
                              <td className="">
                                <div className="active-project-1 d-flex align-items-center mt-0 ">
                                  <div className="h-avatar is-medium">
                                    <img
                                      className="avatar rounded-circle"
                                      alt="user-icon"
                                      src={item.image || avatar}
                                    />
                                  </div>
                                  <div className="data-content">
                                    <div>
                                      <span className="font-weight-bold">
                                        {item.cust_name}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>{item.cust_email}</td>
                              <td>
                                <strong>
                                  {item.deal_revenue.toLocaleString("en-US")}{" "}
                                  AED
                                </strong>
                              </td>
                              <td>{item.dealOwner.name}</td>
                              <td>
                                <strong>
                                  {item.h_quantam.toLocaleString("en-US")} AED
                                </strong>
                              </td>
                              <td>
                                <div className="d-flex justify-content-end align-items-center">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>View</Tooltip>}
                                  >
                                    <Link
                                      className="mr-4"
                                      to={{
                                        pathname: `/view-deal`,
                                        state: { data: item },
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="text-secondary"
                                        width="20"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                        />
                                      </svg>
                                    </Link>
                                  </OverlayTrigger>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};
export default ViewDirectorEmployee;
