// import React, { useState } from "react";
// import { Card } from "react-bootstrap";
// import { Container, Row, Col, Modal } from "react-bootstrap";

// const BusinessReports = () => {
//   return <>hello reports</>;
// };
// export default BusinessReports;
import React, { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  Card,
  Container,
  Row,
  Col,
  Dropdown,
  ListGroup,
  Button,
  Popover,
  OverlayTrigger,
  // Popover,
  // PopoverBody,
} from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";

const BusinessReports = () => {
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const userList = useSelector((state) => state.userReducer.users);
  const [teamSalary, setteamSalary] = useState(12789);

  const [JustificationEmp, setJustificationEmp] = useState(11739);
  const [totalSalary, setTotalSalary] = useState(234322);
  const listArray = [
    {
      date: "19 may 2022",
      project: "hedgeandsacks",
      client: "Jhon Doe",
      status: "Pending",
    },
  ];
  const month = [
    { name: "January" },
    { name: "February" },
    { name: "March" },
    { name: "April" },
    { name: "May" },
    { name: "June" },
    { name: "July" },
    { name: "August" },
    { name: "September" },
    { name: "October" },
    { name: "November" },
    { name: "December" },
  ];
  const selectMonth = () => {
    const salary = teamSalary + 80;
    const Justification = JustificationEmp + 80;
    const total = totalSalary + 80;
    setteamSalary(salary);
    setTotalSalary(total);
  };
  return (
    <Container fluid>
      <Row>
        <Col md="12" className="mb-4 mt-1">
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <h4 className="font-weight-bold">Monthly Overview</h4>
          </div>
        </Col>
        <Col lg="12">
          <Card>
            {/* <ListGroup as="ul" className="list-group-flush">
              <ListGroup.Item as="li"> */}
            <Card.Header>
              <Row>
                <Col md="6">
                  <h4>Please Select Month : </h4>
                </Col>
                <Col md="6">
                  <select
                    onChange={selectMonth}
                    value={"May"}
                    className="form-select form-control mt-2"
                  >
                    <option>Select Month</option>
                    {month.map((data) => {
                      return <option value={data.name}>{data.name}</option>;
                    })}
                  </select>
                </Col>
              </Row>
            </Card.Header>
            <Row>
              <Col md="4">
                <Card
                  style={{ border: "2px solid #8F9FBC ", borderRadius: "5px" }}
                >
                  <Card.Header
                    style={{
                      backgroundColor: "#F0FFFF",
                      borderBottom: "2px solid #8F9FBC",
                    }}
                  >
                    <p className="mb-2 text-secondary">Total Revenue</p>
                  </Card.Header>
                  <Card.Body>
                    {" "}
                    <div className="d-flex flex-wrap justify-content-start align-items-center">
                      <h5 className="mb-0 font-weight-bold">12,000 AED</h5>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="4">
                <Card
                  style={{ border: "2px solid #8F9FBC ", borderRadius: "5px" }}
                >
                  <Card.Header
                    style={{
                      backgroundColor: "#F0FFFF",
                      borderBottom: "2px solid #8F9FBC",
                    }}
                  >
                    <p className="mb-2 text-secondary">Total Unit Sold</p>
                  </Card.Header>
                  <Card.Body>
                    {" "}
                    <div className="d-flex flex-wrap justify-content-start align-items-center">
                      <h5 className="mb-0 font-weight-bold">200,000 AED</h5>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="4">
                <Card
                  style={{ border: "2px solid #8F9FBC ", borderRadius: "5px" }}
                >
                  <Card.Header
                    style={{
                      backgroundColor: "#F0FFFF",
                      borderBottom: "2px solid #8F9FBC",
                    }}
                  >
                    <p className="mb-2 text-secondary">Team Incentive</p>
                  </Card.Header>
                  <Card.Body>
                    {" "}
                    <div className="d-flex flex-wrap justify-content-start align-items-center">
                      <h5 className="mb-0 font-weight-bold">120,000 AED</h5>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Card
                  style={{ border: "2px solid #8F9FBC ", borderRadius: "5px" }}
                >
                  <Card.Header
                    style={{
                      backgroundColor: "#F0FFFF",
                      borderBottom: "2px solid #8F9FBC",
                    }}
                  >
                    <p className="mb-2 text-secondary">Company Fixed Cost</p>
                  </Card.Header>
                  <Card.Body>
                    {" "}
                    <div className="d-flex flex-wrap justify-content-start align-items-center">
                      <h5 className="mb-0 font-weight-bold">120,000 AED</h5>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="4">
                <Card
                  style={{ border: "2px solid #8F9FBC ", borderRadius: "5px" }}
                >
                  <Card.Header
                    style={{
                      backgroundColor: "#F0FFFF",
                      borderBottom: "2px solid #8F9FBC",
                    }}
                  >
                    <p className="mb-2 text-secondary">Team Salary</p>
                  </Card.Header>
                  <Card.Body>
                    {" "}
                    <div className="d-flex flex-wrap justify-content-start align-items-center">
                      <h5 className="mb-0 font-weight-bold">120,000 AED</h5>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="4">
                <Card
                  style={{ border: "2px solid #8F9FBC ", borderRadius: "5px" }}
                >
                  <Card.Header
                    style={{
                      backgroundColor: "#F0FFFF",
                      borderBottom: "2px solid #8F9FBC",
                    }}
                  >
                    <p className="mb-2 text-secondary">Total Salary</p>
                  </Card.Header>
                  <Card.Body>
                    {" "}
                    <div className="d-flex flex-wrap justify-content-start align-items-center">
                      <h5 className="mb-0 font-weight-bold">120,000 AED</h5>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card
                  style={{ border: "2px solid #8F9FBC ", borderRadius: "5px" }}
                >
                  <Card.Header
                    style={{
                      backgroundColor: "#F0FFFF",
                      borderBottom: "2px solid #8F9FBC",
                    }}
                  >
                    <p className="mb-2 text-secondary">Total Commission</p>
                  </Card.Header>
                  <Card.Body>
                    {" "}
                    <div className="d-flex flex-wrap justify-content-start align-items-center">
                      <h5 className="mb-0 font-weight-bold">120,000 AED</h5>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {/* </ListGroup.Item>
            </ListGroup> */}
          </Card>
        </Col>

        {/* <Col lg="12" md="12">
          <Row>
            {(userData.role.name === "Bussiness_Head" ||
              userData.role.name === "PNL") && (
              <>
                <Col md="3">
                  <Card>
                    <Card.Body>
                      <div className="d-flex align-items-center">
                        <div className="">
                          <p className="mb-2 text-secondary">Total Revenue</p>
                          <div className="d-flex flex-wrap justify-content-start align-items-center">
                            <h5 className="mb-0 font-weight-bold">
                              {userList.length}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <Card.Body>
                      <div className="d-flex align-items-center">
                        <div className="">
                          <p className="mb-2 text-secondary">Unit Sold</p>
                          <div className="d-flex flex-wrap justify-content-start align-items-center">
                            <h5 className="mb-0 font-weight-bold">12,789</h5>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <Card.Body>
                      <div className="d-flex align-items-center">
                        <div className="">
                          <p className="mb-2 text-secondary">Team Incentives</p>
                          <div className="d-flex flex-wrap justify-content-start align-items-center">
                            <h5 className="mb-0 font-weight-bold">12,789</h5>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <Card.Body>
                      <div className="d-flex align-items-center">
                        <div className="">
                          <p className="mb-2 text-secondary">
                            Company Fixed Cost
                          </p>
                          <div className="d-flex flex-wrap justify-content-start align-items-center">
                            <h5 className="mb-0 font-weight-bold">12,789</h5>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <Card.Body>
                      <div className="d-flex align-items-center">
                        <div className="">
                          <p className="mb-2 text-secondary">Team Salary</p>
                          <div className="d-flex flex-wrap justify-content-start align-items-center">
                            <h5 className="mb-0 font-weight-bold">
                              12,789 AED
                            </h5>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <Card.Body>
                      <div className="d-flex align-items-center">
                        <div className="">
                          <p className="mb-2 text-secondary">Total Salary</p>
                          <div className="d-flex flex-wrap justify-content-start align-items-center">
                            <h5 className="mb-0 font-weight-bold">12,789</h5>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col md="4">
              <Card className="bg-primary">
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="fit-icon-2 text-info text-center">
                      <div style={{ width: 75, height: 75 }}>
                        <CircularProgressbar
                          styles={buildStyles({
                            textColor: "#c7cbd3",
                            textSize: "23px",
                            pathColor: `#c7cbd3`,
                            trailColor: "#fff",
                          })}
                          value={62}
                          text={"62%"}
                        />
                      </div>
                    </div>
                    <div className="ml-3">
                      <h5 className="text-white font-weight-bold">
                        180k <small> /300k Justification</small>
                      </h5>
                      <small className="mb-0">Justification</small>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col> */}
      </Row>
    </Container>
  );
};
export default BusinessReports;
