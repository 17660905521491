import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Accordion,
  Button,
} from "react-bootstrap";
import Card from "../../../../../components/Card";
import {
  getSlab,
  getUsers,
  setSlab,
} from "../../../../../actions/userAction/userAction";
import {
  getRoles,
  userAddNew,
  addIncentives,
} from "../../../../../actions/AdminAction/adminAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const ViewAdminSlab = () => {
  const dispatch = useDispatch();
  const [activeMenu, setActiveMenu] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const userList = useSelector((state) => state.userReducer.users);
  const roles = useSelector((state) => state.rolesReducer.roles);
  const bArray = useSelector((state) => state.slabReducer.buisnessSlab);
  const oneArray = useSelector((state) => state.slabReducer.tOneSlab);
  const twoArray = useSelector((state) => state.slabReducer.tTwoSlab);
  const threeArray = useSelector((state) => state.slabReducer.tThreeSlab);
  const zeroArray = useSelector((state) => state.slabReducer.tZeroSlab);
  const pnlSlabArray = useSelector((state) => state.slabReducer.pnlSlab);
  let PnlArray = [];
  let tZeroArray = [];
  let tOneArray = [];
  let tThreeArray = [];
  let bussinessArray = [];
  let tTwosArray = [];
  useEffect(() => {
    roles.forEach(async (item) => {
      if (
        item.name !== "SuperAdmin" ||
        item.name !== "ADMIN" ||
        item.name !== "DIRECTOR"
      ) {
        if (item.name === "BUSINESS HEAD") {
          bussinessArray = await dispatch(getSlab({ roleId: item.id }));
          dispatch(setSlab("GET_BUSSINESS_ARRAY", bussinessArray));
        }
        if (item.name === "T2") {
          tTwosArray = await dispatch(getSlab({ roleId: item.id }));
          dispatch(setSlab("GET_T2_ARRAY", tTwosArray));
        }
        if (item.name === "T3") {
          tThreeArray = await dispatch(getSlab({ roleId: item.id }));
          dispatch(setSlab("GET_T3_ARRAY", tThreeArray));
        }
        if (item.name === "T1") {
          tOneArray = await dispatch(getSlab({ roleId: item.id }));
          dispatch(setSlab("GET_T1_ARRAY", tOneArray));
        }
        if (item.name === "T0") {
          tZeroArray = await dispatch(getSlab({ roleId: item.id }));
          dispatch(setSlab("GET_T0_ARRAY", tZeroArray));
        }
        if (item.name === "PNL") {
          PnlArray = await dispatch(getSlab({ roleId: item.id }));
          dispatch(setSlab("GET_PNL_ARRAY", PnlArray));
        }
      }
    });
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="align-items-center justify-content-between my-schedule mb-4 mt-4">
              <Row>
                <Col lg="10">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="font-weight-bold">Commissions Overview</h4>
                  </div>
                </Col>
                <Col lg="2">
                  <div className="create-workform" style={{ float: "right" }}>
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                      <div className="modal-product-search d-flex">
                        <Link
                          to={{
                            pathname: `/add-admin-slab`,
                            state: "",
                          }}
                          className="btn btn-primary position-relative d-flex align-items-center justify-content-between"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-2"
                            width="20"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                            />
                          </svg>
                          Add
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Row>
              <Col lg="12">
                {/* <Card className="card-block card-stretch">
                  <Card.Body className="p-0"> */}
                {/* <div className="d-flex justify-content-between align-items-center p-3">
                  <h5 className="font-weight-bold">Commissions Overflow</h5>
                </div> */}
                <Row className="d-flex">
                  <Col lg="12">
                    <Accordion
                      as="ul"
                      id="mm-sidebar-toggle"
                      className="side-menu d-flex collapsemenu"
                      defaultActiveKey="0"
                    >
                      <Card className="mm-accordion-block accordion accordian-custom">
                        <div className="active-faq clearfix" id="headingOne">
                          <Container fluid>
                            <Row style={{}}>
                              <Col sm="12">
                                <Accordion.Toggle
                                  as={Button}
                                  href="#"
                                  bsPrefix="accordion-title"
                                  eventKey="0"
                                >
                                  <h5 style={{ padding: "15px 0px" }}>
                                    Bussiness Heads{" "}
                                  </h5>
                                </Accordion.Toggle>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                        <div className="">
                          <Row>
                            <Col lg="5">
                              <Accordion.Collapse
                                eventKey="0"
                                className="accordion-details"
                              >
                                <div className="table-responsive">
                                  <h5
                                    style={{
                                      padding: "15px 5px",
                                    }}
                                  >
                                    Revenue{" "}
                                  </h5>
                                  <table className="table data-table mb-0">
                                    <thead className="table-color-heading">
                                      <tr className="">
                                        <th scope="col">Range</th>
                                        <th scope="col">View</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {bArray.map((item, index) => {
                                        return (
                                          <>
                                            {item.commission_type ===
                                              "revenue" && (
                                              <tr key={`${index}bArray`}>
                                                <td>
                                                  {item.upperlimit} -{" "}
                                                  {item.lowerlimit}
                                                </td>
                                                <td>{item.rate}%</td>
                                              </tr>
                                            )}
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </Accordion.Collapse>
                            </Col>
                            <Col lg="2"></Col>
                            <Col lg="5">
                              <Accordion.Collapse
                                eventKey="0"
                                className="accordion-details"
                              >
                                <div className="table-responsive">
                                  <h5
                                    style={{
                                      padding: "15px 5px",
                                    }}
                                  >
                                    Spot{" "}
                                  </h5>

                                  <table className="table data-table mb-0">
                                    <thead className="table-color-heading">
                                      <tr className="">
                                        <th scope="col">Range</th>
                                        <th scope="col">View</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {bArray.map((item, index) => {
                                        return (
                                          <>
                                            {item.commission_type ===
                                              "spot" && (
                                              <tr key={`${index}revenue`}>
                                                <td>
                                                  {item.upperlimit} -{" "}
                                                  {item.lowerlimit}
                                                </td>
                                                <td>{item.rate}%</td>
                                              </tr>
                                            )}
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </Accordion.Collapse>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Accordion>
                  </Col>
                  <Col lg="12">
                    <Accordion
                      as="ul"
                      id="mm-sidebar-toggle"
                      className="side-menu d-flex collapsemenu"
                      defaultActiveKey="0"
                    >
                      <Card className="mm-accordion-block accordion accordian-custom">
                        <div className="active-faq clearfix" id="headingOne">
                          <Container fluid>
                            <Row style={{}}>
                              <Col sm="12">
                                <Accordion.Toggle
                                  as={Button}
                                  href="#"
                                  bsPrefix="accordion-title"
                                  eventKey="0"
                                >
                                  <h5 style={{ padding: "15px 0px" }}>PNL </h5>
                                </Accordion.Toggle>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                        <div className="">
                          <Row>
                            <Col lg="5">
                              <Accordion.Collapse
                                eventKey="0"
                                className="accordion-details"
                              >
                                <div className="table-responsive">
                                  <h5
                                    style={{
                                      padding: "15px 5px",
                                    }}
                                  >
                                    Revenue{" "}
                                  </h5>
                                  <table className="table data-table mb-0">
                                    <thead className="table-color-heading">
                                      <tr className="">
                                        <th scope="col">Range</th>
                                        <th scope="col">View</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {pnlSlabArray.map((item, index) => {
                                        return (
                                          <>
                                            {item.commission_type ===
                                              "revenue" && (
                                              <tr key={`${index}pnlslab`}>
                                                <td>
                                                  {item.upperlimit} -{" "}
                                                  {item.lowerlimit}
                                                </td>
                                                <td>{item.rate}%</td>
                                              </tr>
                                            )}
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </Accordion.Collapse>
                            </Col>
                            <Col lg="2"></Col>
                            <Col lg="5">
                              <Accordion.Collapse
                                eventKey="0"
                                className="accordion-details"
                              >
                                <div className="table-responsive">
                                  <h5
                                    style={{
                                      padding: "15px 5px",
                                    }}
                                  >
                                    Spot{" "}
                                  </h5>

                                  <table className="table data-table mb-0">
                                    <thead className="table-color-heading">
                                      <tr className="">
                                        <th scope="col">Range</th>
                                        <th scope="col">View</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {pnlSlabArray.map((item, index) => {
                                        return (
                                          <>
                                            {item.commission_type ===
                                              "spot" && (
                                              <tr key={`${index}pnlslabarray`}>
                                                <td>
                                                  {item.upperlimit} -{" "}
                                                  {item.lowerlimit}
                                                </td>
                                                <td>{item.rate}%</td>
                                              </tr>
                                            )}
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </Accordion.Collapse>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Accordion>
                  </Col>
                  <Col lg="12">
                    <Accordion
                      as="ul"
                      id="mm-sidebar-toggle"
                      className="side-menu d-flex collapsemenu"
                      defaultActiveKey="0"
                    >
                      <Card className="mm-accordion-block accordion accordian-custom">
                        <div className="active-faq clearfix" id="headingOne">
                          <Container fluid>
                            <Row style={{}}>
                              <Col sm="12">
                                <Accordion.Toggle
                                  as={Button}
                                  href="#"
                                  bsPrefix="accordion-title"
                                  eventKey="0"
                                >
                                  <h5 style={{ padding: "15px 0px" }}>T3</h5>
                                </Accordion.Toggle>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                        <div className="">
                          <Row>
                            <Col lg="5">
                              <Accordion.Collapse
                                eventKey="0"
                                className="accordion-details"
                              >
                                <div className="table-responsive">
                                  <h5
                                    style={{
                                      padding: "15px 5px",
                                    }}
                                  >
                                    Revenue{" "}
                                  </h5>
                                  <table className="table data-table mb-0">
                                    <thead className="table-color-heading">
                                      <tr className="">
                                        <th scope="col">Range</th>
                                        <th scope="col">View</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {threeArray.map((item, index) => {
                                        return (
                                          <>
                                            {item.commission_type ===
                                              "revenue" && (
                                              <tr key={`${index}three`}>
                                                <td>
                                                  {item.upperlimit} -{" "}
                                                  {item.lowerlimit}
                                                </td>
                                                <td>{item.rate}%</td>
                                              </tr>
                                            )}
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </Accordion.Collapse>
                            </Col>
                            <Col lg="2"></Col>
                            <Col lg="5">
                              <Accordion.Collapse
                                eventKey="0"
                                className="accordion-details"
                              >
                                <div className="table-responsive">
                                  <h5
                                    style={{
                                      padding: "15px 5px",
                                    }}
                                  >
                                    Spot{" "}
                                  </h5>

                                  <table className="table data-table mb-0">
                                    <thead className="table-color-heading">
                                      <tr className="">
                                        <th scope="col">Range</th>
                                        <th scope="col">View</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {threeArray.map((item, index) => {
                                        return (
                                          <>
                                            {item.commission_type ===
                                              "spot" && (
                                              <tr key={`${index}threeslab`}>
                                                <td>
                                                  {item.upperlimit} -{" "}
                                                  {item.lowerlimit}
                                                </td>
                                                <td>{item.rate}%</td>
                                              </tr>
                                            )}
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </Accordion.Collapse>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Accordion>
                  </Col>
                  <Col lg="12">
                    <Accordion
                      as="ul"
                      id="mm-sidebar-toggle"
                      className="side-menu d-flex collapsemenu"
                      defaultActiveKey="0"
                    >
                      <Card className="mm-accordion-block accordion accordian-custom">
                        <div className="active-faq clearfix" id="headingOne">
                          <Container fluid>
                            <Row style={{}}>
                              <Col sm="12">
                                <Accordion.Toggle
                                  as={Button}
                                  href="#"
                                  bsPrefix="accordion-title"
                                  eventKey="0"
                                >
                                  <h5 style={{ padding: "15px 0px" }}>T2</h5>
                                </Accordion.Toggle>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                        <div className="">
                          <Row>
                            <Col lg="5">
                              <Accordion.Collapse
                                eventKey="0"
                                className="accordion-details"
                              >
                                <div className="table-responsive">
                                  <h5
                                    style={{
                                      padding: "15px 5px",
                                    }}
                                  >
                                    Revenue{" "}
                                  </h5>
                                  <table className="table data-table mb-0">
                                    <thead className="table-color-heading">
                                      <tr className="">
                                        <th scope="col">Range</th>
                                        <th scope="col">View</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {twoArray.map((item, index) => {
                                        return (
                                          <>
                                            {item.commission_type ===
                                              "revenue" && (
                                              <tr key={`${index}two`}>
                                                <td>
                                                  {item.upperlimit} -{" "}
                                                  {item.lowerlimit}
                                                </td>
                                                <td>{item.rate}%</td>
                                              </tr>
                                            )}
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </Accordion.Collapse>
                            </Col>
                            <Col lg="2"></Col>
                            <Col lg="5">
                              <Accordion.Collapse
                                eventKey="0"
                                className="accordion-details"
                              >
                                <div className="table-responsive">
                                  <h5
                                    style={{
                                      padding: "15px 5px",
                                    }}
                                  >
                                    Spot{" "}
                                  </h5>

                                  <table className="table data-table mb-0">
                                    <thead className="table-color-heading">
                                      <tr className="">
                                        <th scope="col">Range</th>
                                        <th scope="col">View</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {twoArray.map((item, index) => {
                                        return (
                                          <>
                                            {item.commission_type ===
                                              "spot" && (
                                              <tr key={`${index}twoslab`}>
                                                <td>
                                                  {item.upperlimit} -{" "}
                                                  {item.lowerlimit}
                                                </td>
                                                <td>{item.rate}%</td>
                                              </tr>
                                            )}
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </Accordion.Collapse>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Accordion>
                  </Col>
                  <Col lg="12">
                    <Accordion
                      as="ul"
                      id="mm-sidebar-toggle"
                      className="side-menu d-flex collapsemenu"
                      defaultActiveKey="0"
                    >
                      <Card className="mm-accordion-block accordion accordian-custom">
                        <div className="active-faq clearfix" id="headingOne">
                          <Container fluid>
                            <Row style={{}}>
                              <Col sm="12">
                                <Accordion.Toggle
                                  as={Button}
                                  href="#"
                                  bsPrefix="accordion-title"
                                  eventKey="0"
                                >
                                  <h5 style={{ padding: "15px 0px" }}>T1</h5>
                                </Accordion.Toggle>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                        <div className="">
                          <Row>
                            <Col lg="5">
                              <Accordion.Collapse
                                eventKey="0"
                                className="accordion-details"
                              >
                                <div className="table-responsive">
                                  <h5
                                    style={{
                                      padding: "15px 5px",
                                    }}
                                  >
                                    Revenue{" "}
                                  </h5>
                                  <table className="table data-table mb-0">
                                    <thead className="table-color-heading">
                                      <tr className="">
                                        <th scope="col">Range</th>
                                        <th scope="col">View</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {oneArray.map((item, index) => {
                                        return (
                                          <>
                                            {item.commission_type ===
                                              "revenue" && (
                                              <tr key={`${index}one`}>
                                                <td>
                                                  {item.upperlimit} -{" "}
                                                  {item.lowerlimit}
                                                </td>
                                                <td>{item.rate}%</td>
                                              </tr>
                                            )}
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </Accordion.Collapse>
                            </Col>
                            <Col lg="2"></Col>
                            <Col lg="5">
                              <Accordion.Collapse
                                eventKey="0"
                                className="accordion-details"
                              >
                                <div className="table-responsive">
                                  <h5
                                    style={{
                                      padding: "15px 5px",
                                    }}
                                  >
                                    Spot{" "}
                                  </h5>

                                  <table className="table data-table mb-0">
                                    <thead className="table-color-heading">
                                      <tr className="">
                                        <th scope="col">Range</th>
                                        <th scope="col">View</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {oneArray.map((item, index) => {
                                        return (
                                          <>
                                            {item.commission_type ===
                                              "spot" && (
                                              <tr key={`${index}oneslab`}>
                                                <td>
                                                  {item.upperlimit} -{" "}
                                                  {item.lowerlimit}
                                                </td>
                                                <td>{item.rate}%</td>
                                              </tr>
                                            )}
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </Accordion.Collapse>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Accordion>
                  </Col>
                  <Col lg="12">
                    <Accordion
                      as="ul"
                      id="mm-sidebar-toggle"
                      className="side-menu d-flex collapsemenu"
                      defaultActiveKey="0"
                    >
                      <Card className="mm-accordion-block accordion accordian-custom">
                        <div className="active-faq clearfix" id="headingOne">
                          <Container fluid>
                            <Row style={{}}>
                              <Col sm="12">
                                <Accordion.Toggle
                                  as={Button}
                                  href="#"
                                  bsPrefix="accordion-title"
                                  eventKey="0"
                                >
                                  <h5 style={{ padding: "15px 0px" }}>T0</h5>
                                </Accordion.Toggle>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                        <div className="">
                          <Row>
                            <Col lg="5">
                              <Accordion.Collapse
                                eventKey="0"
                                className="accordion-details"
                              >
                                <div className="table-responsive">
                                  <h5
                                    style={{
                                      padding: "15px 5px",
                                    }}
                                  >
                                    Revenue{" "}
                                  </h5>
                                  <table className="table data-table mb-0">
                                    <thead className="table-color-heading">
                                      <tr className="">
                                        <th scope="col">Range</th>
                                        <th scope="col">View</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {zeroArray.map((item, index) => {
                                        return (
                                          <>
                                            {item.commission_type ===
                                              "revenue" && (
                                              <tr key={`${index}zero`}>
                                                <td>
                                                  {item.upperlimit} -{" "}
                                                  {item.lowerlimit}
                                                </td>
                                                <td>{item.rate}%</td>
                                              </tr>
                                            )}
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </Accordion.Collapse>
                            </Col>
                            <Col lg="2"></Col>
                            <Col lg="5">
                              <Accordion.Collapse
                                eventKey="0"
                                className="accordion-details"
                              >
                                <div className="table-responsive">
                                  <h5
                                    style={{
                                      padding: "15px 5px",
                                    }}
                                  >
                                    Spot{" "}
                                  </h5>

                                  <table className="table data-table mb-0">
                                    <thead className="table-color-heading">
                                      <tr className="">
                                        <th scope="col">Range</th>
                                        <th scope="col">View</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {zeroArray.map((item, index) => {
                                        return (
                                          <>
                                            {item.commission_type ===
                                              "spot" && (
                                              <tr key={`${index}zerolab`}>
                                                <td>
                                                  {item.upperlimit} -{" "}
                                                  {item.lowerlimit}
                                                </td>
                                                <td>{item.rate}%</td>
                                              </tr>
                                            )}
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </Accordion.Collapse>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Accordion>
                  </Col>
                </Row>
                {/* </Card.Body>
                </Card> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ViewAdminSlab;
