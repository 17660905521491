import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import Chart from "react-apexcharts";
import * as Yup from "yup";
import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  InputGroup,
  Badge,
} from "react-bootstrap";
import UiBadges from "../../../ui/UiBadges";
import Card from "../../../../../components/Card";
import { Link, useParams, useLocation } from "react-router-dom";
// img
import avatar from "../../../../../assets/images/avatar-3.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import { useDispatch, useSelector } from "react-redux";

const ViewTeam = (props) => {
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const viewRevenue = [
    {
      id: 1,
      date: "19 May 2022",
      revenueAmount: "56345",
    },
    {
      id: 2,
      date: "19 June 2022",
      revenueAmount: "56345",
    },
    {
      id: 3,
      date: "19 July 2022",
      revenueAmount: "56345",
    },
    {
      id: 4,
      date: "16 August 2022",
      revenueAmount: "56345",
    },
  ];
  const viewIncentives = [
    {
      id: 1,
      date: "19 May 2022",
      month: "May",
      year: "2022",
      incentiveAmount: "56345",
    },
    {
      id: 2,
      date: "19 June 2022",
      month: "June",
      year: "2022",
      incentiveAmount: "898677",
    },
    {
      id: 3,
      date: "19 July 2022",
      month: "July",
      year: "2022",
      incentiveAmount: "987879",
    },
    {
      id: 4,
      date: "16 August 2022",
      month: "August",
      year: "2002",
      incentiveAmount: "78788",
    },
  ];

  const chart3 = {
    options: {
      chart: {
        height: 330,
        type: "donut",
      },

      labels: ["Total"],
      colors: ["#0C2556"],
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
          donut: {
            size: "80%",
            labels: {
              show: true,
              total: {
                show: true,
                color: "#BCC1C8",
                fontSize: "18px",
                fontFamily: "DM Sans",
                fontWeight: 600,
              },
              value: {
                show: true,
                fontSize: "25px",
                fontFamily: "DM Sans",
                fontWeight: 700,
                color: "#8F9FBC",
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        lineCap: "round",
      },
      grid: {
        padding: {
          bottom: 0,
        },
      },
      legend: {
        position: "bottom",
        offsetY: 8,
        show: true,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 268,
            },
          },
        },
      ],
    },
    series: [62],
  };

  const dispatch = useDispatch();

  const location = useLocation();
  const [customer, setCustomer] = useState(location.state.data);

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-between">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb p-0 mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/customer">Teams Overview</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Teams Overview Detail View
                    </li>
                  </ol>
                </nav>
              </div>
              <Link
                to="/my-team"
                className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2">Back</span>
              </Link>
            </div>
          </Col>
          <Col lg="12" className="mb-3 d-flex justify-content-between">
            <h4 className="font-weight-bold d-flex align-items-center">
              Teams Overview Detail
            </h4>
          </Col>
        </Row>

        <Row>
          <Col lg="8">
            <Card>
              <ListGroup as="ul" className="list-group-flush">
                <ListGroup.Item as="li">
                  <div>
                    <ListGroup as="ul" className="list-style-1 mb-0">
                      <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-start align-items-center"
                      >
                        <div className="avatar">
                          <img
                            className="avatar avatar-img avatar-60 rounded-circle"
                            src={customer.image || avatar}
                            alt="01.jpg"
                          />
                        </div>
                        <div className="list-style-detail ml-4 mr-2">
                          <h5 className="font-weight-bold">Pankaj joshi</h5>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <table className="table table-borderless mb-0">
                    <tbody>
                      <tr>
                        <td className="p-0">
                          <h4 className="mb-0 text-muted">Salary</h4>
                        </td>
                        <td>
                          <h4
                            style={{ float: "right" }}
                            className="mb-0 text-success d-flex justify-content-start align-items-center"
                          >
                            123432
                          </h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <table className="table table-borderless mb-0">
                    <tbody>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Email ID</p>
                        </td>
                        <td>
                          <p className="mb-0 ">joshi7621@gmail.com</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Birthday</p>
                        </td>
                        <td>
                          <p className="mb-0 ">19 may 1997</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Phone</p>
                        </td>
                        <td>
                          <p className="mb-0 ">8887889</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Country</p>
                        </td>
                        <td>
                          <p className="mb-0 ">UAE</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">State/Region</p>
                        </td>
                        <td>
                          <p className="mb-0 ">Dubai</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Address</p>
                        </td>
                        <td>
                          <p className="mb-0 ">Dubai</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
          <Col lg="4">
            <Card>
              <div className="align-items-center" style={{ padding: "20px" }}>
                <Card.Body>
                  <h4 className="font-weight-bold mb-3">Justification</h4>
                  <Chart
                    className="custom-chart"
                    options={chart3.options}
                    series={chart3.series}
                    type="donut"
                    height="330"
                  />
                  <div
                    className="mt-2"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="#0C2556"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3"
                          y="3"
                          width="18"
                          height="18"
                          rx="2"
                          fill="#0C2556"
                        />
                      </svg>
                    </div>
                    <div>
                      <h5 className="font-weight-bold">1,860 /3k Target</h5>
                      {/* <h6 className="mb-0">Order In Period</h6> */}
                    </div>
                  </div>
                </Card.Body>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Card>
              <Card.Body className="p-0">
                <div className="d-flex justify-content-between align-items-center p-3">
                  <h5>View Incentives</h5>
                </div>
                <div className="table-responsive">
                  <table className="table data-table mb-0">
                    <thead className="table-color-heading">
                      <tr className="">
                        <th scope="col">S.No</th>
                        <th scope="col">Date</th>
                        <th scope="col">Month</th>
                        <th scope="col">Year</th>
                        <th scope="col">Incentives Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {viewIncentives.map((item, index) => {
                        return (
                          <tr>
                            <td>{item.id}</td>
                            <td>{item.date}</td>
                            <td>{item.month}</td>
                            <td>{item.year}</td>
                            <td>{item.incentiveAmount}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="12">
            <Card>
              <Card.Body className="p-0">
                <div className="d-flex justify-content-between align-items-center p-3">
                  <h5>View Revenue</h5>
                </div>
                <div className="table-responsive">
                  <table className="table data-table mb-0">
                    <thead className="table-color-heading">
                      <tr className="">
                        <th scope="col">S.No</th>
                        <th scope="col">Date</th>
                        <th scope="col">Revenue Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {viewRevenue.map((item, index) => {
                        return (
                          <tr>
                            <td>{item.id}</td>
                            <td>{item.date}</td>
                            <td>{item.revenueAmount}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ViewTeam;
