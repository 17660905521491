import React, { useEffect } from "react";
// import { Link } from 'react-router-dom'
import Chart from "react-apexcharts";

import moment from "moment";

//datepicker
import Datepickers from "../../../../../components/Datepicker";

//circularpro
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Card, Container, Row, Col, Dropdown } from "react-bootstrap";

//api
import { getUsers } from "../../../../../actions/userAction/userAction";

import { useDispatch, useSelector } from "react-redux";
const listArray = [
  {
    date: "19 may 2022",
    project: "hedgeandsacks",
    client: "Jhon Doe",
    status: "Pending",
  },
];
const ViewBusiness = () => {
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.userReducer.users);

  useEffect(() => {
    // dispatch(getUsers("Customer"));
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col md="12" className="mb-4 mt-1">
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <h4 className="font-weight-bold">Business Head View</h4>
            <div className="form-group mb-0 vanila-daterangepicker d-flex flex-row">
              <div className="date-icon-set">
                <Datepickers
                  className="vanila-datepicker"
                  names="start"
                  placeholder="From Date"
                />
                <span className="search-link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className=""
                    width="20"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                </span>
              </div>
              <span className="flex-grow-0">
                <span className="btn">To</span>
              </span>
              <div className="date-icon-set">
                <Datepickers names="end" placeholder="To Date" />
                <span className="search-link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className=""
                    width="20"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </Col>
        <Col lg="12" md="12">
          <Row>
            <Col md="3">
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <p className="mb-2 text-secondary">Total Revenue</p>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">
                          {userList.length}
                        </h5>
                        {/* <p className="mb-0 ml-3 text-danger font-weight-bold">
                          -9.98%
                        </p> */}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <p className="mb-2 text-secondary">Commission</p>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">3%</h5>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <p className="mb-2 text-secondary">Unit Sold</p>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">12,789</h5>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <p className="mb-2 text-secondary">Team Incentives</p>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">12,789</h5>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <p className="mb-2 text-secondary">Company Fixed Cost</p>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">12,789</h5>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <p className="mb-2 text-secondary">Team Salary</p>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">12,789</h5>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <p className="mb-2 text-secondary">Justification</p>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">12,789</h5>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <p className="mb-2 text-secondary">Total Salary</p>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">12,789</h5>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <p className="mb-2 text-secondary">PNL</p>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">12,789</h5>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md="4">
              <Card className="bg-primary">
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="fit-icon-2 text-info text-center">
                      <div style={{ width: 75, height: 75 }}>
                        <CircularProgressbar
                          styles={buildStyles({
                            textColor: "#c7cbd3",
                            textSize: "23px",
                            pathColor: `#c7cbd3`,
                            trailColor: "#fff",
                          })}
                          value={62}
                          text={"62%"}
                        />
                      </div>
                    </div>
                    <div className="ml-3">
                      <h5 className="text-white font-weight-bold">
                        1,860 <small> /3k Target</small>
                      </h5>
                      <small className="mb-0">Order In Period</small>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md="12">
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Top Clients</h4>
                  </div>
                  <div className="card-header-toolbar d-flex align-items-center">
                    <Dropdown>
                      <Dropdown.Toggle
                        href="#"
                        variant="text-muted pl-3"
                        id="dropdownMenuButton-customer"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          stroke="currentColor"
                          strokeWidth="2"
                          aria-hidden="true"
                          focusable="false"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <g
                            fill="none"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <circle cx="12" cy="12" r="1" />
                            <circle cx="19" cy="12" r="1" />
                            <circle cx="5" cy="12" r="1" />
                          </g>
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu aria-labelledby="dropdownMenuButton-customer">
                        <Dropdown.Item href="#">
                          <svg
                            className="svg-icon text-secondary"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                            />
                          </svg>
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <svg
                            className="svg-icon text-secondary"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                          </svg>
                          View
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <svg
                            className="svg-icon text-secondary"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Card.Header>
                <Card.Body className="p-0">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="table-color-heading">
                        <tr className="text-secondary">
                          <th scope="col">Date</th>
                          <th scope="col">Client Name</th>
                          <th scope="col">Prject Name</th>
                          <th scope="col" className="text-right">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {listArray &&
                          listArray.slice(0, 5).map((item) => (
                            <tr className="white-space-no-wrap">
                              <td>19 may 2022</td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div>{item.client}</div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div>Hedgeandsacks</div>
                                </div>
                              </td>
                              <td>
                                <p className="mb-0 text-success d-flex justify-content-start align-items-center">
                                  <small>
                                    <svg
                                      className="mr-2"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <circle
                                        cx="12"
                                        cy="12"
                                        r="8"
                                        fill="#3cb72c"
                                      ></circle>
                                    </svg>
                                  </small>{" "}
                                  Completed
                                </p>
                              </td>
                              <td className="text-right"></td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-end align-items-center border-top-table p-3">
                      <button className="btn btn-secondary btn-sm">
                        See All
                      </button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ViewBusiness;
