import React, { useEffect, useState, useRef } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory, useLocation, Link } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Card from "../../../../../components/Card";
import uploadimage from "../../../../../assets/images/add-thumbnail.svg";
import Datepickers from "../../../../../components/Datepicker";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import { useDispatch, useSelector } from "react-redux";
import {
  userAdd,
  userUpdate,
  createCase,
} from "../../../../../actions/userAction/userAction";
import {
  getUsers,
  getTeam,
  getManagerTeam,
  getUser,
} from "../../../../../actions/userAction/userAction";

const AddDeal = (props) => {
  // get customer data
  const location = useLocation();
  const [customer, setCustomer] = useState(location.state?.data);
  const [userImg, setUserImg] = useState("");
  const [dealValue, setDealValue] = useState("");
  const [displayImg, setDisplayImg] = useState("");
  const [visaFileurl, setVisaFileurl] = useState("");
  const [fromdate, setFromDate] = useState("");
  const [addressFileurl, setAddressFileurl] = useState("");
  const [passportFileurl, setPassportFileurl] = useState("");
  const [emiratesFileurl, setEmiratesFileurl] = useState("");
  const [confirmationurl, setConfirmationurl] = useState("");
  const [mailUrl, setMailUrl] = useState("");
  const [paymentUrl, setPaymentUrl] = useState("");
  const [deferrApproval, setDeferrApproval] = useState("");
  const [kyc, setKyc] = useState("");
  const [visaFileurlImage, setVisaFileurlImage] = useState("");
  const [addressFileurlImage, setAddressFileurlImage] = useState("");
  const [passportFileurlImage, setPassportFileurlImage] = useState("");
  const [emiratesFileurlImage, setEmiratesFileurlImage] = useState("");
  const [confirmationurlImage, setConfirmationurlImage] = useState("");
  const [mailUrlImage, setMailUrlImage] = useState("");
  const [paymentUrlImage, setPaymentUrlImage] = useState("");
  const [deferrApprovalImage, setDeferrApprovalImage] = useState("");
  const [kycImage, setKycImage] = useState("");
  const [paymentProofImage, setPaymentProofImage] = useState("");
  const [paymentProof, setPaymentProof] = useState("");
  const [radioButtonValue, setRadioButtonValue] = useState("");
  const [value, setValue] = useState();
  const [rmvalue, setRmValue] = useState();
  const isEdit = !!customer;
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const productType = [
    { name: "Real Estate", id: 1 },
    { name: "Finance", id: 2 },
    { name: "Equity", id: 3 },
  ];
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    deal_owner_id: Yup.string().required("Required"),
    leader_id: Yup.string().required("Required"),
    // pnl_id: Yup.string().required("Required"),
    // cust_name: Yup.string().required("Required"),
    // cust_contact_no: Yup.string().required("Required"),
    // cust_email: Yup.string().email("Invalid email").required("Required"),
    // cust_address: Yup.string().required("Required"),
    // leader_source: Yup.string().required("Required"),
    // project_name: Yup.string().required("Required"),
    // developer_name: Yup.string().required("Required"),
    // unit_details: Yup.string().email("Invalid email").required("Required"),
    // unit_price: Yup.string().required("Required"),
    // down_payment: Yup.string().required("Required"),
    // other_charges: Yup.string().required("Required"),
    // payment_plan: Yup.string().required("Required"),
    // incentive_type: Yup.string().required("Required"),
    // commission_milestone: Yup.string().required("Required"),
    // deal_revenue: Yup.string().required("Required"),
    // date: Yup.string().required("Required"),
    // passback: Yup.string().required("Required"),
    // eos: Yup.string().required("Required"),
    // product_type: Yup.string().required("Required"),
    // h_quantam: Yup.string().required("Required"),
    // amount_recieved: Yup.string().required("Required"),
    // remarks_pnl: Yup.string().required("Required"),
  });

  const dispatch = useDispatch();
  const userList = useSelector((state) => state.userReducer.users);

  useEffect(() => {
    // dispatch(getTeam(userData.id));
    dispatch(getManagerTeam(userData.emp_id));
    dispatch(getUser());
  }, []);

  const teamList = useSelector((state) => state.teamReducer.teams);
  const IncentiveType = [
    { name: "slab", label: "Slab", id: 1 },
    { name: "spot", label: "Spot", id: 1 },
  ];
  const handleImage = (e) => {
    setUserImg(e.target.files[0]);
    setDisplayImg(URL.createObjectURL(e.target.files[0]));
  };

  const removeThumbnail = (e) => {
    setEmiratesFileurl("");
  };

  const removeMail = () => {
    setMailUrl("");
  };

  const removePayment = () => {
    setPaymentUrl("");
  };

  const removeDefferal = () => {
    setDeferrApproval("");
  };

  const removeKyc = () => {
    setKyc("");
  };

  const selectDate = (event) => {
    console.log(event);
  };

  const removeConfirmation = () => {
    setConfirmationurl("");
  };

  const removePassport = () => {
    setPassportFileurl("");
  };

  const removeVisa = () => {
    setVisaFileurl("");
  };

  const removeAddress = () => {
    setAddressFileurl("");
  };

  const uploadVisaThumbnail = (e) => {
    if (e.target.files[0]) {
      setVisaFileurlImage(e.target.files[0]);
      setVisaFileurl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const uploadAddressThumbnail = (e) => {
    if (e.target.files[0]) {
      setAddressFileurlImage(e.target.files[0]);
      setAddressFileurl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const uploadPassportThumbnail = (e) => {
    if (e.target.files[0]) {
      setPassportFileurlImage(e.target.files[0]);
      setPassportFileurl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const uploadConfirmationUrl = (e) => {
    if (e.target.files[0]) {
      setConfirmationurlImage(e.target.files[0]);
      setConfirmationurl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const uploadmailUrl = (e) => {
    if (e.target.files[0]) {
      setMailUrlImage(e.target.files[0]);
      setMailUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const uploadEmiratesThumbnail = (e) => {
    if (e.target.files[0]) {
      setEmiratesFileurlImage(e.target.files[0]);
      setEmiratesFileurl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const uploadPaymentUrl = (e) => {
    if (e.target.files[0]) {
      setPaymentUrlImage(e.target.files[0]);
      setPaymentUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const refferalApproval = (e) => {
    if (e.target.files[0]) {
      setDeferrApprovalImage(e.target.files[0]);
      setDeferrApproval(URL.createObjectURL(e.target.files[0]));
    }
  };

  const uploadKYCThumbnail = (e) => {
    if (e.target.files[0]) {
      setKycImage(e.target.files[0]);
      setKyc(URL.createObjectURL(e.target.files[0]));
    }
  };

  const uploadPaymentProofUrl = (e) => {
    if (e.target.files[0]) {
      setPaymentProofImage(e.target.files[0]);
      setPaymentProof(URL.createObjectURL(e.target.files[0]));
    }
  };

  const removePaymentUploadDoc = () => {
    setPaymentProof("");
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="d-flex flex-wrap align-items-center justify-content-end">
              {/* <div className="d-flex align-items-center justify-content-between">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb p-0 mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/customer">Customers</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {isEdit ? "Edit User" : "Add User"}
                    </li>
                  </ol>
                </nav>
              </div> */}
              <Link
                to="/list-deal"
                className="btn btn-primary btn-sm d-flex align-items-center justify-content-between"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2">Back</span>
              </Link>
            </div>
          </Col>
          <Col lg="12" className="mb-3 d-flex justify-content-between">
            <h4 className="font-weight-bold0 d-flex align-items-center">
              {isEdit ? "Edit User" : "Add Deal"}
            </h4>
          </Col>
          <Formik
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              values["cust_name"].toUpperCase();
              values["buisnesshead_id"] = userData.id;
              // const payload = {
              values.deal_revenue = dealValue;
              values.status = "created";
              values.tcf_createDate = fromdate;
              values.sharing = radioButtonValue === "TRUE" ? true : false;
              values.userId = userData.id;
              values.cut_kyc = kycImage;
              values.cust_emiratesId = emiratesFileurlImage;
              values.cust_passport = passportFileurlImage;
              values.cust_addressProof = addressFileurlImage;
              values.cust_visa = visaFileurlImage;
              values.mail_confirmation = mailUrlImage;
              values.deferral_approval = deferrApprovalImage;
              values.payment_proof_doc = paymentProofImage;
              delete values.deal_new_revenue;
              delete values.revenue_for_fact;
              console.log("uiuiuuuuui", values);
              await dispatch(createCase(values));
              history.push("/list-deal");
            }}
            enableReinitialize={true}
            initialValues={{
              deal_owner_id: userData ? userData.id : "",
              leader_id: userData ? userData.id : "",
              pnl_id: userData ? userData.id : "",
              buisnesshead_id: userData ? userData.name : "",
              cust_name: "",
              cust_contact_no: "",
              cust_email: "",
              cust_address: "",
              leader_source: "",
              project_name: "",
              developer_name: "",
              unit_details: "",
              unit_price: "",
              down_payment: "",
              other_charges: "",
              payment_plan: "",
              incentive_type: "",
              commission_milestone: "",
              deal_revenue: null,
              passback: "",
              // EOS: "",
              // date: "",
              product_type: "",
              h_quantam: "",
              h_amount_recieved: "",
              remarks_pnl: "",
              payment_proof: "",
              status: "created",
              tcf_confirmDate: null,
              tcf_countedDate: null,
              rm_name: "",
              rm_mob: "",
              sharing_employee: "",
              sharing: "",
            }}
          >
            {({ handleSubmit, handleChange, touched, errors, values }) => (
              <Col lg="12">
                <Card>
                  <Card.Body>
                    <Form noValidate onSubmit={handleSubmit}>
                      <Row>
                        <Col md="12">
                          <Card.Header>
                            <Card.Header.Title>
                              <h4 className="card-title">
                                {isEdit ? "Edit Case" : "Add Case"}
                              </h4>
                            </Card.Header.Title>
                          </Card.Header>
                          <Card.Body>
                            <div className="new-user-info">
                              <Row>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Deal Owner</label>
                                    <Field
                                      as="select"
                                      className={`form-control ${
                                        touched.deal_owner_id &&
                                        errors.deal_owner_id
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="First Name"
                                      name="deal_owner_id"
                                    >
                                      <option>Select deal owner</option>
                                      {teamList &&
                                        teamList.map((data, index) => {
                                          return (
                                            <>
                                              <option value={data.id}>
                                                {data.name}
                                              </option>
                                            </>
                                          );
                                        })}
                                    </Field>
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="deal_owner_id"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Leader Name</label>
                                    <Field
                                      as="select"
                                      className={`form-control ${
                                        touched.leader_id && errors.leader_id
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Last Name"
                                      name="leader_id"
                                    >
                                      <option>Select leader name</option>
                                      {teamList &&
                                        teamList.map((data, index) => {
                                          return (
                                            <>
                                              <option value={data.id}>
                                                {data.name}
                                              </option>
                                            </>
                                          );
                                        })}
                                    </Field>
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="leader_id"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">
                                      Buissness Head name
                                    </label>
                                    <Field
                                      type="text"
                                      className={`form-control ${
                                        touched.buisnesshead_id &&
                                        errors.buisnesshead_id
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      disabled={values ? true : false}
                                      placeholder="Enter PNL"
                                      name="buisnesshead_id"
                                    />

                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="buisnesshead_id"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">PNL Name</label>
                                    <Field
                                      as="select"
                                      className={`form-control ${
                                        touched.pnl_id && errors.pnl_id
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="First Name"
                                      name="pnl_id"
                                    >
                                      <option>Select pnl name</option>
                                      {teamList &&
                                        teamList.map((data, index) => {
                                          return (
                                            <>
                                              {data.role.name === "PNL" && (
                                                <option value={data.id}>
                                                  {data.name}
                                                </option>
                                              )}
                                            </>
                                          );
                                        })}
                                    </Field>
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="pnl_id"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Customer name</label>
                                    <Field
                                      type="text"
                                      className={`form-control ${
                                        touched.cust_name && errors.cust_name
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Enter Customer Name"
                                      name="cust_name"
                                    />
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="cust_name"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">
                                      Customer Contact Number
                                    </label>
                                    <Field
                                      type="number"
                                      className={`form-control ${
                                        touched.cust_contact_no &&
                                        errors.cust_contact_no
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Enter Customer Number"
                                      name="cust_contact_no"
                                    />
                                    {/* <PhoneInput
                                      style={{
                                        border: "1px solid #f1f1f1",
                                        padding: "12px 4px",
                                      }}
                                      placeholder="Enter Customer number"
                                      name="cust_contact_no"
                                      value={value}
                                      onChange={setValue}
                                    /> */}

                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="cust_contact_no"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Customer Email</label>
                                    <Field
                                      type="email"
                                      className={`form-control ${
                                        touched.cust_email && errors.cust_email
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Enter Customer Email"
                                      name="cust_email"
                                    />
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="cust_email"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Customer Address</label>
                                    <Field
                                      type="text"
                                      className={`form-control ${
                                        touched.cust_address &&
                                        errors.cust_address
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Enter Customer Address"
                                      name="cust_address"
                                    />
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="cust_address"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Proof Of Payment</label>
                                    <Field
                                      as="select"
                                      className={`form-control ${
                                        touched.payment_proof &&
                                        errors.payment_proof
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      name="payment_proof"
                                    >
                                      <option>Select payent of proof</option>
                                      <option value="Cheque">Cheque</option>
                                      <option value="Cash">Cash</option>
                                      <option value="POS">POS</option>
                                    </Field>
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="payment_proof"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Lead Source</label>
                                    <Field
                                      as="select"
                                      className={`form-control ${
                                        touched.leader_source &&
                                        errors.leader_source
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Enter First Name"
                                      name="leader_source"
                                    >
                                      <option>Select Lead Source</option>
                                      <option value="Marketting">
                                        Marketting
                                      </option>
                                      <option value="Cold Calling">
                                        Cold Calling
                                      </option>
                                      <option value="Self">Self</option>
                                    </Field>
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="leadSource"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">TCF Created Date</label>
                                    <Datepickers
                                      type="text"
                                      className="vanila-datepicker"
                                      name="tcf_createDate"
                                      showFutureDates={true}
                                      onBlur={(e) =>
                                        setFromDate(e.target.value)
                                      }
                                      placeholder="Enter Date"
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Sharing</label>
                                    <br />
                                    <input
                                      className="mr-2"
                                      type="radio"
                                      id="html"
                                      name="sharing"
                                      value="true"
                                      onChange={() => {
                                        setRadioButtonValue("TRUE");
                                      }}
                                    />
                                    <label for="true" className="mr-4">
                                      True
                                    </label>
                                    <input
                                      className="mr-2"
                                      type="radio"
                                      id="css"
                                      name="sharing"
                                      value="false"
                                      checked={
                                        radioButtonValue === "" ||
                                        radioButtonValue === "FALSE"
                                      }
                                      onChange={(e) => {
                                        setRadioButtonValue("FALSE");
                                      }}
                                    />
                                    <label for="false">False</label>
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="leadSource"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                {radioButtonValue === "TRUE" && (
                                  <div className="col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="">Sharing Emplyoee</label>
                                      <Field
                                        as="select"
                                        className={`form-control ${
                                          touched.sharing_employee &&
                                          errors.sharing_employee
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        name="sharing_employee"
                                      >
                                        <option>Select sharing employee</option>
                                        {userList &&
                                          userList.map((data) => {
                                            return (
                                              <option value={data.id}>
                                                {data.name}
                                              </option>
                                            );
                                          })}
                                      </Field>
                                      <ErrorMessage
                                        className="invalid-feedback"
                                        name="sharing_employee"
                                        component="span"
                                      />
                                    </div>
                                  </div>
                                )}
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Quantam</label>
                                    <Field
                                      type="number"
                                      className={`form-control ${
                                        touched.customer_address &&
                                        errors.customer_address
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Enter quantam amount"
                                      name="h_quantam"
                                    />
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="h_quantam"
                                      component="span"
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">
                                      Reveneue Percentage
                                    </label>
                                    <Field
                                      as="select"
                                      className={`form-control ${
                                        touched.revenue_for_fact &&
                                        errors.revenue_for_fact
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Enter First Name"
                                      name="revenue_for_fact"
                                      onChange={(e) => {
                                        console.log(e.target.value);
                                        const data =
                                          (values.h_quantam * e.target.value) /
                                          100;
                                        setDealValue(data);
                                      }}
                                    >
                                      <option>Select Revenue fact</option>
                                      <option value="5">5</option>
                                      <option value="10">10</option>
                                      <option value="15">15</option>
                                      <option value="20">20</option>
                                      <option value="25">25</option>
                                      <option value="30">30</option>
                                    </Field>
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="revenue_for_fact"
                                      component="span"
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Deal Revenue</label>
                                    <Field
                                      type="number"
                                      className={`form-control ${
                                        touched.deal_revenue &&
                                        errors.deal_revenue
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      disabled={true}
                                      value={dealValue}
                                      placeholder="Enter deal revenue"
                                      name="deal_revenue"
                                    />
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="deal_revenue"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">PNL Remarks</label>
                                    <Field
                                      type="textarea"
                                      className={`form-control ${
                                        touched.remarks_pnl &&
                                        errors.remarks_pnl
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Enter remarks"
                                      name="remarks_pnl"
                                    />
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="remarks_pnl"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Product Type</label>
                                    <Field
                                      as="select"
                                      className={`form-control ${
                                        touched.product_type &&
                                        errors.product_type
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Enter Incentives Amount"
                                      name="product_type"
                                    >
                                      <option>Select Product Type</option>
                                      {productType.map((data, index) => {
                                        return (
                                          <>
                                            <option value={data.name}>
                                              {data.name}
                                            </option>
                                          </>
                                        );
                                      })}
                                    </Field>
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="product_type"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                {values.product_type === "Real Estate" && (
                                  <>
                                    {/* <div className="col-md-12 col-sm-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="">Incentive Type</label>
                                        <Field
                                          as="select"
                                          className={`form-control ${
                                            touched.product_type &&
                                            errors.product_type
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          disabled={true}
                                          placeholder="Enter Incentives Amount"
                                          name="incentive_type"
                                        >
                                          {IncentiveType.map((data, index) => {
                                            return (
                                              <>
                                                <option
                                                  value={data.name}
                                                  selected={
                                                    data.name === "spot"
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  {data.label}
                                                </option>
                                              </>
                                            );
                                          })}
                                        </Field>
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="product_type"
                                          component="span"
                                        />
                                      </div>
                                    </div> */}
                                    <div className="col-md-6 col-sm-12">
                                      <div className="form-group mb-6">
                                        <label htmlFor="">Project Name</label>
                                        <Field
                                          type="text"
                                          className={`form-control ${
                                            touched.project_name &&
                                            errors.project_name
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Enter Project Name"
                                          name="project_name"
                                        />
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="project_name"
                                          component="span"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                      <div className="form-group mb-6">
                                        <label htmlFor="">Developer Name</label>
                                        <Field
                                          type="text"
                                          className={`form-control ${
                                            touched.developer_name &&
                                            errors.developer_name
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Enter Developer Name"
                                          name="developer_name"
                                        />
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="developer_name"
                                          component="span"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                      <div className="form-group mb-6">
                                        <label htmlFor="">Unit Details</label>
                                        <Field
                                          type="text"
                                          className={`form-control ${
                                            touched.unit_details &&
                                            errors.unit_details
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Enter Unit Details"
                                          name="unit_details"
                                        />
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="unit_details"
                                          component="span"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                      <div className="form-group mb-6">
                                        <label htmlFor="">Unit Price</label>
                                        <Field
                                          type="number"
                                          className={`form-control ${
                                            touched.unit_price &&
                                            errors.unit_price
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Enter Unit Price"
                                          name="unit_price"
                                        />
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="unit_price"
                                          component="span"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                      <div className="form-group mb-6">
                                        <label htmlFor="">Down payment</label>
                                        <Field
                                          type="number"
                                          className={`form-control ${
                                            touched.down_payment &&
                                            errors.down_payment
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Enter Down payment"
                                          name="down_payment"
                                        />
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="down_payment"
                                          component="span"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                      <div className="form-group mb-6">
                                        <label htmlFor="">Other charge</label>
                                        <Field
                                          type="number"
                                          className={`form-control ${
                                            touched.other_charges &&
                                            errors.other_charges
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Enter Other Charge"
                                          name="other_charges"
                                        />
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="other_charges"
                                          component="span"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                      <div className="form-group mb-6">
                                        <label htmlFor="">Payment Plan</label>
                                        <Field
                                          type="number"
                                          className={`form-control ${
                                            touched.payment_plan &&
                                            errors.payment_plan
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Enter number of months"
                                          name="payment_plan"
                                        />
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="payment_plan"
                                          component="span"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="">
                                          Commission Milestone
                                        </label>
                                        <Field
                                          as="select"
                                          className={`form-control ${
                                            touched.commission_milestone &&
                                            errors.commission_milestone
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Enter commission milestone"
                                          name="commission_milestone"
                                        >
                                          <option>
                                            Select Comission Milestone
                                          </option>
                                          <option value="5">5</option>
                                          <option value="10">10</option>
                                          <option value="15">15</option>
                                          <option value="20">20</option>
                                          <option value="25">25</option>
                                          <option value="30">30</option>
                                          <option value="35">35</option>
                                          <option value="40">40</option>
                                          <option value="45">45</option>
                                          <option value="50">50</option>
                                          <option value="55">55</option>
                                          <option value="60">60</option>
                                          <option value="65">65</option>
                                          <option value="70">70</option>
                                          <option value="75">75</option>
                                          <option value="80">80</option>
                                          <option value="85">85</option>
                                          <option value="90">90</option>
                                          <option value="95">95</option>
                                          <option value="100">100</option>
                                        </Field>
                                      </div>
                                    </div>
                                    {/* <div className="col-md-6 col-sm-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="">Revenue</label>
                                        <Field
                                          as="select"
                                          className={`form-control ${
                                            touched.deal_revenue &&
                                            errors.deal_revenue
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          name="deal_revenue"
                                        >
                                          <option>Select revenue</option>
                                          <option value="5">5</option>
                                          <option value="10">10</option>
                                          <option value="15">15</option>
                                          <option value="20">20</option>
                                          <option value="25">25</option>
                                          <option value="30">30</option>
                                        </Field>
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="deal_revenue"
                                          component="span"
                                        />
                                      </div>
                                    </div> */}
                                    <div className="col-md-6 col-sm-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="">Pass Back</label>
                                        <Field
                                          type="text"
                                          className={`form-control ${
                                            touched.passback && errors.passback
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Enter pass back"
                                          name="passback"
                                        />
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="passback"
                                          component="span"
                                        />
                                      </div>
                                    </div>
                                    {/* <div className="col-md-6 col-sm-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="">
                                          TCF Created Date
                                        </label>
                                        <Datepickers
                                          type="text"
                                          className="vanila-datepicker"
                                          name="tcf_createDate"
                                          showFutureDates={true}
                                          onBlur={(e) =>
                                            setFromDate(e.target.value)
                                          }
                                          placeholder="Enter Date"
                                          autoComplete="off"
                                        />
                                      </div>
                                    </div> */}
                                    <div className="col-md-6 col-sm-12">
                                      <div className="form-group mb-6">
                                        <label htmlFor="">RMName</label>
                                        <Field
                                          type="text"
                                          className={`form-control ${
                                            touched.rm_name && errors.rm_name
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Enter rm_name"
                                          name="rm_name"
                                        />
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="rm_name"
                                          component="span"
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-6 col-sm-12">
                                      <div className="form-group mb-6">
                                        <label htmlFor="">
                                          RM Mobile Number
                                        </label>
                                        <Field
                                          type="text"
                                          className={`form-control ${
                                            touched.rm_mob && errors.rm_mob
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Enter rm_mob"
                                          name="rm_mob"
                                        />
                                        {/* <PhoneInput
                                          style={{
                                            border: "1px solid #f1f1f1",
                                            padding: "12px 4px",
                                          }}
                                          placeholder="Enter Customer number"
                                          name="rm_mob"
                                          value={rmvalue}
                                          onChange={setRmValue}
                                        /> */}

                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="rm_mob"
                                          component="span"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="">
                                          Attachments Of Soft Copy
                                        </label>
                                      </div>
                                      <div>
                                        <label htmlFor="">KYC</label>
                                        <div className="row mt-2">
                                          <div className="col-6 col-md-3">
                                            <div className="input-thumb">
                                              <label for="thumb-file-kyc">
                                                <img src={uploadimage} alt="" />
                                              </label>
                                              <input
                                                type="file"
                                                className="d-none"
                                                id="thumb-file-kyc"
                                                autoComplete="false"
                                                accept="image/*"
                                                onChangeCapture={(e) => {
                                                  uploadKYCThumbnail(e);
                                                }}
                                              />
                                            </div>
                                          </div>
                                          {kyc ? (
                                            <div className="col-6 col-md-3">
                                              <div className="thumb-preview">
                                                <img src={kyc} alt="" />
                                                <div className="overlay"></div>
                                                <button
                                                  className="btn-reset"
                                                  type="button"
                                                  onClickCapture={removeKyc}
                                                >
                                                  <i className="fas fa-trash"></i>
                                                </button>
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div>
                                        <label htmlFor="">Emirates ID</label>
                                        <div className="row mt-2">
                                          <div className="col-6 col-md-3">
                                            <div className="input-thumb">
                                              <label for="thumb-file-emirates">
                                                <img src={uploadimage} alt="" />
                                              </label>
                                              <input
                                                type="file"
                                                className="d-none"
                                                id="thumb-file-emirates"
                                                autoComplete="false"
                                                accept="image/*"
                                                onChangeCapture={(e) => {
                                                  uploadEmiratesThumbnail(e);
                                                }}
                                              />
                                            </div>
                                          </div>
                                          {emiratesFileurl ? (
                                            <div className="col-6 col-md-3">
                                              <div className="thumb-preview">
                                                <img
                                                  src={emiratesFileurl}
                                                  alt=""
                                                />
                                                <div className="overlay"></div>
                                                <button
                                                  className="btn-reset"
                                                  type="button"
                                                  onClickCapture={
                                                    removeThumbnail
                                                  }
                                                >
                                                  <i className="fas fa-trash"></i>
                                                </button>
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div>
                                        <label htmlFor="" className="mt-4">
                                          Passport
                                        </label>
                                        <div className="row mt-2">
                                          <div className="col-6 col-md-3">
                                            <div className="input-thumb">
                                              <label for="thumb-file-passport">
                                                <img src={uploadimage} alt="" />
                                              </label>
                                              <input
                                                type="file"
                                                className="d-none"
                                                id="thumb-file-passport"
                                                autoComplete="false"
                                                accept="image/*"
                                                onChangeCapture={(e) => {
                                                  uploadPassportThumbnail(e);
                                                }}
                                              />
                                            </div>
                                          </div>
                                          {passportFileurl ? (
                                            <div className="col-6 col-md-3">
                                              <div className="thumb-preview">
                                                <img
                                                  src={passportFileurl}
                                                  alt=""
                                                />
                                                <div className="overlay"></div>
                                                <button
                                                  className="btn-reset"
                                                  type="button"
                                                  onClickCapture={() => {
                                                    removePassport();
                                                  }}
                                                >
                                                  <i className="fas fa-trash"></i>
                                                </button>
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div>
                                        <label htmlFor="" className="mt-4">
                                          Address Proof
                                        </label>
                                        <div className="row mt-2">
                                          <div className="col-6 col-md-3">
                                            <div className="input-thumb">
                                              <label for="thumb-file-address">
                                                <img src={uploadimage} alt="" />
                                              </label>
                                              <input
                                                type="file"
                                                className="d-none"
                                                id="thumb-file-address"
                                                autoComplete="false"
                                                accept="image/*"
                                                onChangeCapture={(e) => {
                                                  uploadAddressThumbnail(e);
                                                }}
                                              />
                                            </div>
                                          </div>
                                          {addressFileurl ? (
                                            <div className="col-6 col-md-3">
                                              <div className="thumb-preview">
                                                <img
                                                  src={addressFileurl}
                                                  alt=""
                                                />
                                                <div className="overlay"></div>
                                                <button
                                                  className="btn-reset"
                                                  type="button"
                                                  onClickCapture={removeAddress}
                                                >
                                                  <i className="fas fa-trash"></i>
                                                </button>
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div>
                                        <label htmlFor="" className="mt-4">
                                          Visa
                                        </label>
                                        <div className="row mt-4">
                                          <div className="col-6 col-md-3">
                                            <div className="input-thumb">
                                              <label for="thumb-file-visa">
                                                <img src={uploadimage} alt="" />
                                              </label>
                                              <input
                                                type="file"
                                                className="d-none"
                                                id="thumb-file-visa"
                                                autoComplete="false"
                                                accept="image/*"
                                                onChangeCapture={(e) => {
                                                  uploadVisaThumbnail(e);
                                                }}
                                              />
                                            </div>
                                          </div>
                                          {visaFileurl ? (
                                            <div className="col-6 col-md-3">
                                              <div className="thumb-preview">
                                                <img src={visaFileurl} alt="" />
                                                <div className="overlay"></div>
                                                <button
                                                  className="btn-reset"
                                                  type="button"
                                                  onClickCapture={removeVisa}
                                                >
                                                  <i className="fas fa-trash"></i>
                                                </button>
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                      {/* <div>
                                        <label htmlFor="">
                                          Unit Confirmation Mail
                                        </label>
                                        <div className="row mt-2">
                                          <div className="col-6 col-md-3">
                                            <div className="input-thumb">
                                              <label for="thumb-file-mail">
                                                <img src={uploadimage} alt="" />
                                              </label>
                                              <input
                                                type="file"
                                                className="d-none"
                                                id="thumb-file-mail"
                                                autoComplete="false"
                                                accept="image/*"
                                                onChangeCapture={(e) => {
                                                  uploadConfirmationUrl(e);
                                                }}
                                              />
                                            </div>
                                          </div>
                                          {confirmationurl ? (
                                            <div className="col-6 col-md-3">
                                              <div className="thumb-preview">
                                                <img
                                                  src={confirmationurl}
                                                  alt=""
                                                />
                                                <div className="overlay"></div>
                                                <button
                                                  className="btn-reset"
                                                  type="button"
                                                  onClickCapture={
                                                    removeConfirmation
                                                  }
                                                >
                                                  <i className="fas fa-trash"></i>
                                                </button>
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div> */}
                                    </div>
                                  </>
                                )}
                                {values.product_type === "Finance" && (
                                  <>
                                    {/* <div className="col-md-12 col-sm-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="">Incentive Type</label>
                                        <Field
                                          as="select"
                                          className={`form-control ${
                                            touched.product_type &&
                                            errors.product_type
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Enter Incentives Amount"
                                          name="incentive_type"
                                        >
                                          {IncentiveType.map((data, index) => {
                                            return (
                                              <>
                                                <option value={data.name}>
                                                  {data.label}
                                                </option>
                                              </>
                                            );
                                          })}
                                        </Field>
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="product_type"
                                          component="span"
                                        />
                                      </div>
                                    </div> */}
                                    <div className="col-md-6 col-sm-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="">
                                          Amount Recieved
                                        </label>
                                        <Field
                                          type="number"
                                          className={`form-control ${
                                            touched.h_amount_recieved &&
                                            errors.h_amount_recieved
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Enter Amount Recieved"
                                          name="h_amount_recieved"
                                        />
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="h_amount_recieved"
                                          component="span"
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-12 col-sm-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="">
                                          Attachments Of Soft Copy
                                        </label>
                                      </div>
                                      <div>
                                        <label htmlFor="">
                                          Mail Confirmation
                                        </label>
                                        <div className="row mt-2">
                                          <div className="col-6 col-md-3">
                                            <div className="input-thumb">
                                              <label for="thumb-file-confirmation">
                                                <img src={uploadimage} alt="" />
                                              </label>
                                              <input
                                                type="file"
                                                className="d-none"
                                                id="thumb-file-confirmation"
                                                autoComplete="false"
                                                accept="image/*"
                                                onChangeCapture={(e) => {
                                                  uploadmailUrl(e);
                                                }}
                                              />
                                            </div>
                                          </div>
                                          {mailUrl ? (
                                            <div className="col-6 col-md-3">
                                              <div className="thumb-preview">
                                                <img src={mailUrl} alt="" />
                                                <div className="overlay"></div>
                                                <button
                                                  className="btn-reset"
                                                  type="button"
                                                  onClickCapture={removeMail}
                                                >
                                                  <i className="fas fa-trash"></i>
                                                </button>
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>

                                      <div>
                                        <label htmlFor="">
                                          Deferral Approval
                                        </label>
                                        <div className="row mt-2">
                                          <div className="col-6 col-md-3">
                                            <div className="input-thumb">
                                              <label for="thumb-file-deferral">
                                                <img src={uploadimage} alt="" />
                                              </label>
                                              <input
                                                type="file"
                                                className="d-none"
                                                id="thumb-file-deferral"
                                                autoComplete="false"
                                                accept="image/*"
                                                onChangeCapture={(e) => {
                                                  refferalApproval(e);
                                                }}
                                              />
                                            </div>
                                          </div>
                                          {deferrApproval ? (
                                            <div className="col-6 col-md-3">
                                              <div className="thumb-preview">
                                                <img
                                                  src={deferrApproval}
                                                  alt=""
                                                />
                                                <div className="overlay"></div>
                                                <button
                                                  className="btn-reset"
                                                  type="button"
                                                  onClickCapture={
                                                    removeDefferal
                                                  }
                                                >
                                                  <i className="fas fa-trash"></i>
                                                </button>
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {values.product_type === "Equity" && (
                                  <>
                                    {/* <div className="col-md-12 col-sm-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="">Incentive Type</label>
                                        <Field
                                          as="select"
                                          className={`form-control ${
                                            touched.product_type &&
                                            errors.product_type
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Enter Incentives Amount"
                                          name="incentive_type"
                                        >
                                          {IncentiveType.map((data, index) => {
                                            return (
                                              <>
                                                <option value={data.name}>
                                                  {data.label}
                                                </option>
                                              </>
                                            );
                                          })}
                                        </Field>
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="product_type"
                                          component="span"
                                        />
                                      </div>
                                    </div> */}
                                    {/* <div className="col-md-6 col-sm-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="">Quantam</label>
                                        <Field
                                          type="number"
                                          className={`form-control ${
                                            touched.customer_address &&
                                            errors.customer_address
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Enter quantam number"
                                          name="h_quantam"
                                        />
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="h_quantam"
                                          component="span"
                                        />
                                      </div>
                                    </div> */}
                                    <div className="col-md-6 col-sm-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="">
                                          Amount Recieved
                                        </label>
                                        <Field
                                          type="text"
                                          className={`form-control ${
                                            touched.h_amount_recieved &&
                                            errors.h_amount_recieved
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Enter amount Received"
                                          name="h_amount_recieved"
                                        />
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="h_amount_recieved"
                                          component="span"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="">
                                          Attachments Of Soft Copy
                                        </label>
                                      </div>

                                      <div>
                                        <label htmlFor="">
                                          Mail Confirmation
                                        </label>
                                        <div className="row mt-2">
                                          <div className="col-6 col-md-3">
                                            <div className="input-thumb">
                                              <label for="thumb-file-mailurl">
                                                <img src={uploadimage} alt="" />
                                              </label>
                                              <input
                                                type="file"
                                                className="d-none"
                                                id="thumb-file-mailurl"
                                                autoComplete="false"
                                                accept="image/*"
                                                onChangeCapture={(e) => {
                                                  uploadmailUrl(e);
                                                }}
                                              />
                                            </div>
                                          </div>
                                          {mailUrl ? (
                                            <div className="col-6 col-md-3">
                                              <div className="thumb-preview">
                                                <img src={mailUrl} alt="" />
                                                <div className="overlay"></div>
                                                <button
                                                  className="btn-reset"
                                                  type="button"
                                                  onClickCapture={removeMail}
                                                >
                                                  <i className="fas fa-trash"></i>
                                                </button>
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div>
                                        <label htmlFor="">
                                          Deferral Approval
                                        </label>
                                        <div className="row mt-2">
                                          <div className="col-6 col-md-3">
                                            <div className="input-thumb">
                                              <label for="thumb-file-def">
                                                <img src={uploadimage} alt="" />
                                              </label>
                                              <input
                                                type="file"
                                                className="d-none"
                                                id="thumb-file-def"
                                                autoComplete="false"
                                                accept="image/*"
                                                onChangeCapture={(e) => {
                                                  refferalApproval(e);
                                                }}
                                              />
                                            </div>
                                          </div>
                                          {deferrApproval ? (
                                            <div className="col-6 col-md-3">
                                              <div className="thumb-preview">
                                                <img
                                                  src={deferrApproval}
                                                  alt=""
                                                />
                                                <div className="overlay"></div>
                                                <button
                                                  className="btn-reset"
                                                  type="button"
                                                  onClickCapture={
                                                    removeDefferal
                                                  }
                                                >
                                                  <i className="fas fa-trash"></i>
                                                </button>
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {(values.product_type === "Real Estate" ||
                                  values.product_type === "Finance" ||
                                  values.product_type === "Equity") && (
                                  <>
                                    <div>
                                      <label htmlFor="">
                                        Payment Proof Document
                                      </label>
                                      <div className="row mt-2">
                                        <div className="col-6 col-md-3">
                                          <div className="input-thumb">
                                            <label for="thumb-file-paymentProof">
                                              <img src={uploadimage} alt="" />
                                            </label>
                                            <input
                                              type="file"
                                              className="d-none"
                                              id="thumb-file-paymentProof"
                                              autoComplete="false"
                                              accept="image/*"
                                              onChangeCapture={(e) => {
                                                uploadPaymentProofUrl(e);
                                              }}
                                            />
                                          </div>
                                        </div>
                                        {paymentProof ? (
                                          <div className="col-6 col-md-3">
                                            <div className="thumb-preview">
                                              <img src={paymentProof} alt="" />
                                              <div className="overlay"></div>
                                              <button
                                                className="btn-reset"
                                                type="button"
                                                onClickCapture={removeMail}
                                              >
                                                <i className="fas fa-trash"></i>
                                              </button>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </Row>
                              <div className="d-flex justify-content-end mt-3">
                                <Button
                                  type="submit"
                                  variant="btn btn-primary"
                                  // disabled={
                                  //   Array.isArray(errors) ||
                                  //   Object.values(errors).toString() != ""
                                  // }
                                >
                                  Add Case
                                </Button>
                              </div>
                            </div>
                          </Card.Body>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Formik>
        </Row>
      </Container>
    </>
  );
};

export default AddDeal;
