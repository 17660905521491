import {
  GET_BUSSINESS_ARRAY,
  GET_PNL_ARRAY,
  GET_T3_ARRAY,
  GET_T2_ARRAY,
  GET_T1_ARRAY,
  GET_T0_ARRAY,
  GET_INDIVIDUAL_ARRAY,
} from "../../constants/actionTypes";

const initialState = {
  buisnessSlab: [],
  tOneSlab: [],
  tTwoSlab: [],
  tThreeSlab: [],
  tZeroSlab: [],
  pnlSlab: [],
  seperateSlab: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BUSSINESS_ARRAY:
      return {
        ...state,
        buisnessSlab: action.payload,
      };
    case GET_T1_ARRAY:
      return {
        ...state,
        tOneSlab: action.payload,
      };
    case GET_T2_ARRAY:
      return {
        ...state,
        tTwoSlab: action.payload,
      };
    case GET_T3_ARRAY:
      return {
        ...state,
        tThreeSlab: action.payload,
      };
    case GET_T0_ARRAY:
      return {
        ...state,
        tZeroSlab: action.payload,
      };
    case GET_PNL_ARRAY:
      return {
        ...state,
        pnlSlab: action.payload,
      };
    case GET_INDIVIDUAL_ARRAY:
      return {
        ...state,
        seperateSlab: action.payload,
      };

    default:
      return state;
  }
}
