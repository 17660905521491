import React, { useEffect, useState } from "react";
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import Card from "../../../../../components/Card";
import { Link } from "react-router-dom";
import { getUsers } from "../../../../../actions/userAction/userAction";
import avatar from "../../../../../assets/images/avatar-3.png";
import { useDispatch, useSelector } from "react-redux";

const ListBusiness = () => {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const userList = useSelector((state) => state.userReducer.users);
  useEffect(() => {
    // dispatch(getUsers("Customer"));
  }, []);
  const business = [
    { name: "Pankaj Joshi", id: "1" },
    { name: "Dheeraj Joshi", id: "2" },
  ];
  const [q, setQ] = useState("");
  const [searchTerm] = useState(["email", "firstname", "lastname"]);

  const handleSearch = (items) => {
    return items.filter((item) => {
      return searchTerm.some((newItem) => {
        return (
          item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="font-weight-bold">Business Heads</h4>
              </div>
            </div>
            <Row>
              <Col lg="12">
                <Card className="card-block card-stretch">
                  <Card.Body className="p-0">
                    <div className="d-flex justify-content-between align-items-center p-3">
                      <h5 className="font-weight-bold">Business Heads List</h5>
                    </div>
                    <div className="table-responsive">
                      <table className="table data-table mb-0">
                        <thead className="table-color-heading">
                          <tr className="">
                            <th scope="col">Name</th>
                            <th scope="col" className="text-right">
                              View
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {business &&
                            business.map((item) => (
                              <tr key={item.id} className="white-space-no-wrap">
                                <td className="">
                                  <div className="active-project-1 d-flex align-items-center mt-0 ">
                                    <div className="h-avatar is-medium">
                                      <img
                                        className="avatar rounded-circle"
                                        alt="user-icon"
                                        src={item.image || avatar}
                                      />
                                    </div>
                                    <div className="data-content">
                                      <div>
                                        <span className="font-weight-bold">
                                          {item.name}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex justify-content-end align-items-center">
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip>View</Tooltip>}
                                    >
                                      <Link
                                        className="mr-4"
                                        to={{
                                          pathname: `/view-business/${item.id}`,
                                          state: { data: item },
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="text-secondary"
                                          width="20"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                          />
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                          />
                                        </svg>
                                      </Link>
                                    </OverlayTrigger>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ListBusiness;
