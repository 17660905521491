import config from "../../config";
import * as api from "../../common/api";
import * as actionTypes from "../../constants/actionTypes";
import * as localstorage from "../../common/localStorage";

const loginSuccess = (type, text) => ({
  type,
  payload: text,
});

const successMessage = (type, text) => ({
  type,
  payload: text,
});

const errorMessage = (type, text) => ({
  type,
  payload: text,
});

const statusInfo = (type) => ({
  type,
});

export const signout = () => (dispatch) => {
  dispatch({
    type: actionTypes.AUTH_ERROR,
  });
  dispatch(
    successMessage(actionTypes.SUCCESS_MESSAGE, "Signed Out Successfully")
  );
};

export const login = (loginDetails) => async (dispatch) => {
  dispatch(statusInfo(actionTypes.RESET_STATUS));
  const { email, password } = loginDetails;
  const payload = { email, password };
  await api
    .post(`${config.BASE_URL}login`, payload)
    .then((response) => {
      console.log("ioioooiiiio", response);
      if (response.status == 200 || response.status == 201) {
        localstorage.set("token", response.data.token);
        localstorage.set("userdata", JSON.stringify(response.data));
        dispatch(statusInfo(actionTypes.SUCCESS_STATUS));
        dispatch(loginSuccess(actionTypes.USER_LOADED, response.data));
        dispatch(
          successMessage(actionTypes.SUCCESS_MESSAGE, "Logged In Successfully")
        );
      }
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.AUTH_ERROR,
      });
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.message));
      dispatch(statusInfo(actionTypes.ERROR_STATUS));
    });
};
