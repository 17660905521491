import { Switch, Route, Link, Redirect } from "react-router-dom";
import SignIn from "../views/backend/Auth/SignIn";
import PankajLayout from "../layouts/PankajLayout";
import PrivateRoute from "./PrivateRoute";

const LayoutsRoute = (props) => {
  return (
    <Switch>
      <PrivateRoute path="/" component={PankajLayout} exact />

      {/* Business head*/}
      <PrivateRoute path="/my-team" component={PankajLayout} exact />
      <PrivateRoute path="/view-team/:id" component={PankajLayout} exact />
      <PrivateRoute path="/my-clients" component={PankajLayout} exact />
      <PrivateRoute path="/reports" component={PankajLayout} exact />
      <PrivateRoute path="/list-deal" component={PankajLayout} exact />
      <PrivateRoute path="/view-deal" component={PankajLayout} exact />
      <PrivateRoute path="/add-deal" component={PankajLayout} exact />
      <PrivateRoute path="/view-business/:id" component={PankajLayout} exact />
      <PrivateRoute path="/list-business" component={PankajLayout} exact />
      <PrivateRoute path="/my-slab" component={PankajLayout} exact />

      {/* Admin Routes */}
      <PrivateRoute
        path="/list-admin-employee"
        component={PankajLayout}
        exact
      />
      <PrivateRoute
        path="/view-admin-employee/:id"
        component={PankajLayout}
        exact
      />
      <PrivateRoute path="/list-admin-team" component={PankajLayout} exact />
      <PrivateRoute
        path="/view-admin-team/:id"
        component={PankajLayout}
        exact
      />
      <PrivateRoute path="/view-admin-slab" component={PankajLayout} exact />
      <PrivateRoute path="/add-admin-slab" component={PankajLayout} exact />
      <PrivateRoute path="/admin-cases" component={PankajLayout} exact />
      <PrivateRoute
        path="/view-admin-cases/:id"
        component={PankajLayout}
        exact
      />
      <PrivateRoute path="/add-incentives" component={PankajLayout} exact />
      <PrivateRoute
        path="/director-view-employee"
        component={PankajLayout}
        exact
      />
      <PrivateRoute path="/add-user-data" component={PankajLayout} exact />

      <PrivateRoute
        path="/view-director-employee/:id"
        component={PankajLayout}
        exact
      />
      <PrivateRoute path="/client-details/:id" component={PankajLayout} exact />
      <PrivateRoute path="/company-fixed-cost" component={PankajLayout} exact />
      {!props.auth.isAuthenticated && (
        <Route path="/sign-in" component={SignIn} />
      )}
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default LayoutsRoute;
