import config from "../../config";
import * as ajaxCall from "../../common/ajaxCall";
import * as actionTypes from "../../constants/actionTypes";
import axios from "axios";
const successMessage = (type, text) => ({
  type,
  payload: text,
});

const errorMessage = (type, text) => ({
  type,
  payload: text,
});

const statusInfo = (type) => ({
  type,
});

const getUserList = (type, users) => ({
  type: type,
  payload: users,
});

const teamList = (type, team) => ({
  type: type,
  payload: team,
});

const setSlabArray = (type, data) => ({
  type: type,
  payload: data,
});

const setCaseList = (type, data) => ({
  type: type,
  payload: data,
});

export const getTeam = (payload) => async (dispatch) => {
  dispatch(statusInfo(actionTypes.RESET_STATUS));
  axios.defaults.headers.common[
    "Authorization"
  ] = `bearer ${localStorage.getItem("token")}`;
  var headers = {
    "Content-Type": "application/json",
  };
  const requestPayload = {
    user_id: payload,
  };
  return await axios
    .post(`${config.BASE_URL}get_team`, requestPayload, { headers: headers })
    .then((response) => {
      dispatch(statusInfo(actionTypes.SUCCESS_STATUS));
      dispatch(teamList(actionTypes.TEAM_LIST, response.data));
      //   dispatch({
      //     type: actionTypes.GET_DOCUMENTS,
      //     payload: response.data.data,
      //   });
      // }
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.message));
      dispatch(statusInfo(actionTypes.ERROR_STATUS));
    });
};

export const getUser = (payload) => async (dispatch) => {
  dispatch(statusInfo(actionTypes.RESET_STATUS));
  axios.defaults.headers.common[
    "Authorization"
  ] = `bearer ${localStorage.getItem("token")}`;
  var headers = {
    "Content-Type": "application/json",
  };
  return await axios
    .get(`${config.BASE_URL}user`, { headers: headers })
    .then((response) => {
      // if (response.status == 200) {
      dispatch(statusInfo(actionTypes.SUCCESS_STATUS));
      dispatch(getUserList(actionTypes.LIST_USER, response.data));
      // }
    })
    .catch((error) => {
      // dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.data.message));
      dispatch(statusInfo(actionTypes.ERROR_STATUS));
    });
};

export const userToBeAdd = (payload) => async (dispatch) => {
  dispatch(statusInfo(actionTypes.RESET_STATUS));
  axios.defaults.headers.common[
    "Authorization"
  ] = `bearer ${localStorage.getItem("token")}`;
  var headers = {
    "Content-Type": "application/json",
  };

  return await axios
    .post(`${config.BASE_URL}add_to_team`, payload, { headers: headers })
    .then((response) => {
      dispatch(statusInfo(actionTypes.SUCCESS_STATUS));
      // dispatch(getUserList(actionTypes.LIST_USER, response.data));
      dispatch(
        successMessage(
          actionTypes.SUCCESS_MESSAGE,
          "Member Added successfully."
        )
      );
      return true;
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.message));
      dispatch(statusInfo(actionTypes.ERROR_STATUS));
      return false;
    });
};

export const createCase = (data) => async (dispatch) => {
  dispatch(statusInfo(actionTypes.RESET_STATUS));
  axios.defaults.headers.common[
    "Authorization"
  ] = `bearer ${localStorage.getItem("token")}`;
  var headers = {
    "Content-Type": "application/json",
  };

  return await ajaxCall
    .post(`${config.BASE_URL}case`, data, { headers: headers })
    .then((response) => {
      dispatch(statusInfo(actionTypes.SUCCESS_STATUS));
      // dispatch(getUserList(actionTypes.LIST_USER, response.data));
      dispatch(
        successMessage(
          actionTypes.SUCCESS_MESSAGE,
          "Case Created Successfully."
        )
      );
      return true;
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.message));
      dispatch(statusInfo(actionTypes.ERROR_STATUS));
      return false;
    });
};

export const getCase = (payload) => async (dispatch) => {
  dispatch(statusInfo(actionTypes.RESET_STATUS));
  axios.defaults.headers.common[
    "Authorization"
  ] = `bearer ${localStorage.getItem("token")}`;
  var headers = {
    "Content-Type": "application/json",
  };
  return await axios
    .get(`${config.BASE_URL}get_user_cases/${payload.userId}`, {
      headers: headers,
    })
    .then((response) => {
      dispatch(statusInfo(actionTypes.SUCCESS_STATUS));
      dispatch(setCaseList(actionTypes.LIST_CASES, response.data));
      return true;
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.message));
      dispatch(statusInfo(actionTypes.ERROR_STATUS));
      return false;
    });
};

export const getAllCase = (payload) => async (dispatch) => {
  dispatch(statusInfo(actionTypes.RESET_STATUS));
  axios.defaults.headers.common[
    "Authorization"
  ] = `bearer ${localStorage.getItem("token")}`;
  var headers = {
    "Content-Type": "application/json",
  };
  return await axios
    .post(`${config.BASE_URL}get_case`, {
      headers: headers,
    })
    .then((response) => {
      dispatch(statusInfo(actionTypes.SUCCESS_STATUS));
      dispatch(setCaseList(actionTypes.LIST_CASES, response.data));
      // dispatch(
      //   successMessage(
      //     actionTypes.SUCCESS_MESSAGE,
      //     "Case Created Successfully."
      //   )
      // );
      return true;
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.message));
      dispatch(statusInfo(actionTypes.ERROR_STATUS));
      return false;
    });
};

export const caseStatusUpdate = (payload) => async (dispatch) => {
  dispatch(statusInfo(actionTypes.RESET_STATUS));
  axios.defaults.headers.common[
    "Authorization"
  ] = `bearer ${localStorage.getItem("token")}`;
  var headers = {
    "Content-Type": "application/json",
  };
  return await axios
    .put(`${config.BASE_URL}case/${payload.id}`, payload.data, {
      headers: headers,
    })
    .then((response) => {
      dispatch(statusInfo(actionTypes.SUCCESS_STATUS));
      // dispatch(getUserList(actionTypes.LIST_USER, response.data));
      dispatch(
        successMessage(
          actionTypes.SUCCESS_MESSAGE,
          "Case Status Updated Successfully."
        )
      );
      return true;
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.message));
      dispatch(statusInfo(actionTypes.ERROR_STATUS));
      return false;
    });
};

export const getMonthlyCases = (payload) => async (dispatch) => {
  dispatch(statusInfo(actionTypes.RESET_STATUS));
  axios.defaults.headers.common[
    "Authorization"
  ] = `bearer ${localStorage.getItem("token")}`;
  var headers = {
    "Content-Type": "application/json",
  };
  return await axios
    .post(`${config.BASE_URL}get_mothly_cases`, payload, {
      headers: headers,
    })
    .then((response) => {
      dispatch(statusInfo(actionTypes.SUCCESS_STATUS));
      dispatch(setCaseList(actionTypes.LIST_CASES, response.data));
      dispatch(
        successMessage(
          actionTypes.SUCCESS_MESSAGE,
          "Case Status Updated Successfully."
        )
      );
      return true;
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.message));
      dispatch(statusInfo(actionTypes.ERROR_STATUS));
      return false;
    });
};

export const addSalary = (payload) => async (dispatch) => {
  dispatch(statusInfo(actionTypes.RESET_STATUS));
  axios.defaults.headers.common[
    "Authorization"
  ] = `bearer ${localStorage.getItem("token")}`;
  var headers = {
    "Content-Type": "application/json",
  };
  return await axios
    .post(`${config.BASE_URL}salary`, payload, {
      headers: headers,
    })
    .then((response) => {
      dispatch(statusInfo(actionTypes.SUCCESS_STATUS));
      // dispatch(getUserList(actionTypes.LIST_USER, response.data));
      dispatch(
        successMessage(
          actionTypes.SUCCESS_MESSAGE,
          "Salary Added Successfully."
        )
      );
      return true;
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.message));
      dispatch(statusInfo(actionTypes.ERROR_STATUS));
      return false;
    });
};

export const updateSalary = (payload) => async (dispatch) => {
  dispatch(statusInfo(actionTypes.RESET_STATUS));
  axios.defaults.headers.common[
    "Authorization"
  ] = `bearer ${localStorage.getItem("token")}`;
  var headers = {
    "Content-Type": "application/json",
  };
  return await axios
    .put(`${config.BASE_URL}salary/${payload.id}`, payload.data, {
      headers: headers,
    })
    .then((response) => {
      dispatch(statusInfo(actionTypes.SUCCESS_STATUS));
      // dispatch(getUserList(actionTypes.LIST_USER, response.data));
      dispatch(
        successMessage(
          actionTypes.SUCCESS_MESSAGE,
          "Salary Updated Successfully."
        )
      );
      return true;
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.message));
      dispatch(statusInfo(actionTypes.ERROR_STATUS));
      return false;
    });
};

export const getSalary = (payload) => async (dispatch) => {
  dispatch(statusInfo(actionTypes.RESET_STATUS));
  axios.defaults.headers.common[
    "Authorization"
  ] = `bearer ${localStorage.getItem("token")}`;
  var headers = {
    "Content-Type": "application/json",
  };
  return await axios
    .get(`${config.BASE_URL}get_salary`, payload, {
      headers: headers,
    })
    .then((response) => {
      dispatch(statusInfo(actionTypes.SUCCESS_STATUS));
      // dispatch(getUserList(actionTypes.LIST_USER, response.data));
      // dispatch(
      //   successMessage(
      //     actionTypes.SUCCESS_MESSAGE,
      //     "Salary Added Successfully."
      //   )
      // );
      return true;
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.message));
      dispatch(statusInfo(actionTypes.ERROR_STATUS));
      return false;
    });
};

export const addSlab = (payload) => async (dispatch) => {
  dispatch(statusInfo(actionTypes.RESET_STATUS));
  axios.defaults.headers.common[
    "Authorization"
  ] = `bearer ${localStorage.getItem("token")}`;
  var headers = {
    "Content-Type": "application/json",
  };
  return await axios
    .post(`${config.BASE_URL}slab`, payload, {
      headers: headers,
    })
    .then((response) => {
      dispatch(statusInfo(actionTypes.SUCCESS_STATUS));
      // dispatch(getUserList(actionTypes.LIST_USER, response.data));
      dispatch(
        successMessage(actionTypes.SUCCESS_MESSAGE, "Slab Added Successfully.")
      );
      return true;
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.message));
      dispatch(statusInfo(actionTypes.ERROR_STATUS));
      return false;
    });
};

export const updateSlab = (payload) => async (dispatch) => {
  dispatch(statusInfo(actionTypes.RESET_STATUS));
  axios.defaults.headers.common[
    "Authorization"
  ] = `bearer ${localStorage.getItem("token")}`;
  var headers = {
    "Content-Type": "application/json",
  };
  return await axios
    .put(`${config.BASE_URL}slab/${payload.id}`, payload.data, {
      headers: headers,
    })
    .then((response) => {
      dispatch(statusInfo(actionTypes.SUCCESS_STATUS));
      // dispatch(getUserList(actionTypes.LIST_USER, response.data));
      dispatch(
        successMessage(
          actionTypes.SUCCESS_MESSAGE,
          "Slab Updated Successfully."
        )
      );
      return true;
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.message));
      dispatch(statusInfo(actionTypes.ERROR_STATUS));
      return false;
    });
};

export const getSlab = (payload) => async (dispatch) => {
  dispatch(statusInfo(actionTypes.RESET_STATUS));
  axios.defaults.headers.common[
    "Authorization"
  ] = `bearer ${localStorage.getItem("token")}`;
  var headers = {
    "Content-Type": "application/json",
  };
  return await axios
    .post(`${config.BASE_URL}get_slab`, payload, {
      headers: headers,
    })
    .then((response) => {
      dispatch(statusInfo(actionTypes.SUCCESS_STATUS));
      // dispatch(getUserList(actionTypes.LIST_USER, response.data));
      // dispatch(
      //   successMessage(
      //     actionTypes.SUCCESS_MESSAGE,
      //     "Salary Added Successfully."
      //   )
      // );
      return response.data;
      return true;
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.message));
      dispatch(statusInfo(actionTypes.ERROR_STATUS));
      return false;
    });
};

export const getManagerTeam = (payload) => async (dispatch) => {
  dispatch(statusInfo(actionTypes.RESET_STATUS));
  axios.defaults.headers.common[
    "Authorization"
  ] = `bearer ${localStorage.getItem("token")}`;
  var headers = {
    "Content-Type": "application/json",
  };
  return await axios
    .get(`${config.BASE_URL}get_manager_user/${payload}`, payload, {
      headers: headers,
    })
    .then((response) => {
      dispatch(statusInfo(actionTypes.SUCCESS_STATUS));
      dispatch(teamList(actionTypes.TEAM_LIST, response.data));

      // dispatch(getUserList(actionTypes.LIST_USER, response.data));
      // dispatch(
      //   successMessage(
      //     actionTypes.SUCCESS_MESSAGE,
      //     "Salary Added Successfully."
      //   )
      // );
      return response.data;
      return true;
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.message));
      dispatch(statusInfo(actionTypes.ERROR_STATUS));
      return false;
    });
};

export const setSlab = (types, payload) => async (dispatch) => {
  if (types === "GET_BUSSINESS_ARRAY") {
    dispatch(setSlabArray(actionTypes.GET_BUSSINESS_ARRAY, payload));
  }
  if (types === "GET_PNL_ARRAY") {
    dispatch(setSlabArray(actionTypes.GET_PNL_ARRAY, payload));
  }
  if (types === "GET_T3_ARRAY") {
    dispatch(setSlabArray(actionTypes.GET_T3_ARRAY, payload));
  }
  if (types === "GET_T2_ARRAY") {
    dispatch(setSlabArray(actionTypes.GET_T2_ARRAY, payload));
  }
  if (types === "GET_T1_ARRAY") {
    dispatch(setSlabArray(actionTypes.GET_T1_ARRAY, payload));
  }
  if (types === "GET_T0_ARRAY") {
    dispatch(setSlabArray(actionTypes.GET_T0_ARRAY, payload));
  }
  if (types === "GET_INDIVIDUAL_ARRAY") {
    dispatch(setSlabArray(actionTypes.GET_INDIVIDUAL_ARRAY, payload));
  }
};
