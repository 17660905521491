import React, { useEffect } from "react";
// import { Link } from 'react-router-dom'
import Chart from "react-apexcharts";

import moment from "moment";

//leaflet
import Leaflet from "../../../components/leaflet";

//datepicker
import Datepickers from "../../../components/Datepicker";

//circularpro
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  Card,
  Container,
  Row,
  Col,
  Dropdown,
  Button,
  Popover,
  OverlayTrigger,
  // Popover,
  // PopoverBody,
} from "react-bootstrap";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Popover from "react-bootstrap/Popover";

// img
import product1 from "../../../assets/images/products/1.jpg";
import product2 from "../../../assets/images/products/2.jpg";
import product13 from "../../../assets/images/products/13.jpg";
import product4 from "../../../assets/images/products/4.jpg";
import user1 from "../../../assets/images/user/1.jpg";
import user5 from "../../../assets/images/user/5.jpg";
import user2 from "../../../assets/images/user/2.jpg";
import user3 from "../../../assets/images/user/3.jpg";

//api
import {
  getCase,
  getManagerTeam,
} from "../../../actions/userAction/userAction";

import { useDispatch, useSelector } from "react-redux";

const Dashbord = () => {
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.userReducer.users);
  const casesList = useSelector((state) => state.caseReducer.casesList);
  const teamList = useSelector((state) => state.teamReducer.teams);
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const month = [
    { name: "January", id: 1 },
    { name: "February", id: 2 },
    { name: "March", id: 3 },
    { name: "April", id: 4 },
    { name: "May", id: 5 },
    { name: "June", id: 6 },
    { name: "July", id: 7 },
    { name: "August", id: 8 },
    { name: "September", id: 9 },
    { name: "October", id: 10 },
    { name: "November", id: 11 },
    { name: "December", id: 12 },
  ];
  const listArray = [
    {
      date: "19 may 2022",
      project: "hedgeandsacks",
      client: "Jhon Doe",
      status: "Pending",
    },
  ];
  const businessHeadSalaries = [
    {
      id: 7,
      salary: 15950,
    },
    {
      id: 6,
      salary: 14850,
    },
    {
      id: 17,
      salary: 13000,
    },
  ];
  // let ownSalary = businessHeadSalaries.find(
  //   (item) => item.id == userData.id
  // ).salary;
  useEffect(() => {
    dispatch(getCase({ userId: userData.id }));
    dispatch(getManagerTeam(userData.emp_id));
  }, []);

  let revenue = 0;
  let teamSalary = 0;
  casesList.map((item) => (revenue = revenue + item.deal_revenue));
  teamList.map((item) => (teamSalary = teamSalary + item.salary.amount));
  const totalSalary = teamSalary + 0;

  const totalRevenuePopover = (
    <Popover id="popover-basic">
      <Popover.Content>Total Revenue in current month</Popover.Content>
    </Popover>
  );
  const commissionPopover = (
    <Popover id="popover-basic">
      <Popover.Content>
        And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
  );
  const unitSoldPopover = (
    <Popover id="popover-basic">
      <Popover.Content>Total Units Sold in current month</Popover.Content>
    </Popover>
  );
  const incentivePopover = (
    <Popover id="popover-basic">
      <Popover.Content>Total team incentive in current month</Popover.Content>
    </Popover>
  );
  const fixedCostPopover = (
    <Popover id="popover-basic">
      <Popover.Content>Comapny cost in current month</Popover.Content>
    </Popover>
  );
  const teamSalaryPopover = (
    <Popover id="popover-basic">
      <Popover.Content>Total team's salary</Popover.Content>
    </Popover>
  );
  const JustificationPopover = (
    <Popover id="popover-basic">
      <Popover.Content>
        And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
  );
  const totalSalaryPopover = (
    <Popover id="popover-basic">
      <Popover.Content>
        And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
  );
  const pnlPopover = (
    <Popover id="popover-basic">
      <Popover.Content>
        And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
  );

  return (
    <Container fluid>
      <Row>
        <Col md="12" className="mb-4 mt-1">
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <h4 className="font-weight-bold">Current Month Overview</h4>
            <div className="form-group mb-0 vanila-daterangepicker d-flex flex-row">
              <div>
                <select
                  className="form-select form-control"
                  onSelect={() => {
                    console.log("hlos");
                  }}
                >
                  {month.map((data) => {
                    return (
                      <option
                        value={data.id}
                        selected={new Date().getMonth() + 1 === data.id}
                      >
                        {data.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </Col>

        <Col lg="12" md="12">
          <Row>
            {(userData.role.name === "BUSINESS HEAD" ||
              userData.role.name === "PNL") && (
              <>
                <OverlayTrigger
                  trigger="click"
                  placement="top"
                  overlay={totalRevenuePopover}
                >
                  <Col md="3">
                    <Card>
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <div className="">
                            <p className="mb-2 text-secondary">Total Revenue</p>
                            <div className="d-flex flex-wrap justify-content-start align-items-center">
                              <h5 className="mb-0 font-weight-bold">
                                {revenue.toLocaleString("en-US")} AED
                              </h5>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger
                  trigger="click"
                  placement="top"
                  overlay={unitSoldPopover}
                >
                  <Col md="3">
                    <Card>
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <div className="">
                            <p className="mb-2 text-secondary">
                              Total Real Estate Deals
                            </p>
                            <div className="d-flex flex-wrap justify-content-start align-items-center">
                              <h5 className="mb-0 font-weight-bold">0</h5>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger
                  trigger="click"
                  placement="top"
                  overlay={unitSoldPopover}
                >
                  <Col md="3">
                    <Card>
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <div className="">
                            <p className="mb-2 text-secondary">
                              Total Finance Deals
                            </p>
                            <div className="d-flex flex-wrap justify-content-start align-items-center">
                              <h5 className="mb-0 font-weight-bold">
                                {casesList.length}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger
                  trigger="click"
                  placement="top"
                  overlay={incentivePopover}
                >
                  <Col md="3">
                    <Card>
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <div className="">
                            <p className="mb-2 text-secondary">
                              Team Incentives
                            </p>
                            <div className="d-flex flex-wrap justify-content-start align-items-center">
                              <h5 className="mb-0 font-weight-bold">0</h5>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger
                  trigger="click"
                  placement="top"
                  overlay={fixedCostPopover}
                >
                  <Col md="3">
                    <Card>
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <div className="">
                            <p className="mb-2 text-secondary">
                              Company Fixed Cost
                            </p>
                            <div className="d-flex flex-wrap justify-content-start align-items-center">
                              <h5 className="mb-0 font-weight-bold">0</h5>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger
                  trigger="click"
                  placement="top"
                  overlay={teamSalaryPopover}
                >
                  <Col md="3">
                    <Card>
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <div className="">
                            <p className="mb-2 text-secondary">Team Salary</p>
                            <div className="d-flex flex-wrap justify-content-start align-items-center">
                              <h5 className="mb-0 font-weight-bold">
                                {teamSalary.toLocaleString("en-US")} AED
                              </h5>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger
                  trigger="click"
                  placement="top"
                  overlay={totalSalaryPopover}
                >
                  <Col md="3">
                    <Card>
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <div className="">
                            <p className="mb-2 text-secondary">Total Salary</p>
                            <div className="d-flex flex-wrap justify-content-start align-items-center">
                              <h5 className="mb-0 font-weight-bold">
                                {totalSalary.toLocaleString("en-US")} AED
                              </h5>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </OverlayTrigger>
              </>
            )}
            {userData.role.name === "DIRECTOR" && (
              <>
                {" "}
                <OverlayTrigger
                  trigger="click"
                  placement="top"
                  overlay={totalRevenuePopover}
                >
                  <Col md="4">
                    <Card>
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <div className="">
                            <p className="mb-2 text-secondary">Total Revenue</p>
                            <div className="d-flex flex-wrap justify-content-start align-items-center">
                              <h5 className="mb-0 font-weight-bold">
                                7687687 AED
                              </h5>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger
                  trigger="click"
                  placement="top"
                  overlay={unitSoldPopover}
                >
                  <Col md="4">
                    <Card>
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <div className="">
                            <p className="mb-2 text-secondary">Unit Sold</p>
                            <div className="d-flex flex-wrap justify-content-start align-items-center">
                              <h5 className="mb-0 font-weight-bold">12,789</h5>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger
                  trigger="click"
                  placement="top"
                  overlay={totalRevenuePopover}
                >
                  <Col md="4">
                    <Card>
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <div className="">
                            <p className="mb-2 text-secondary">
                              Total Incentives
                            </p>
                            <div className="d-flex flex-wrap justify-content-start align-items-center">
                              <h5 className="mb-0 font-weight-bold">
                                765776 AED
                              </h5>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger
                  trigger="click"
                  placement="top"
                  overlay={commissionPopover}
                >
                  <Col md="4">
                    <Card>
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <div className="">
                            <p className="mb-2 text-secondary">
                              Company Fixed Cost
                            </p>
                            <div className="d-flex flex-wrap justify-content-start align-items-center">
                              <h5 className="mb-0 font-weight-bold">
                                30000 AED
                              </h5>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger
                  trigger="click"
                  placement="top"
                  overlay={unitSoldPopover}
                >
                  <Col md="4">
                    <Card>
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <div className="">
                            <p className="mb-2 text-secondary">PNL</p>
                            <div className="d-flex flex-wrap justify-content-start align-items-center">
                              <h5 className="mb-0 font-weight-bold">12676</h5>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </OverlayTrigger>
              </>
            )}
          </Row>
          <Row>
            <Col md="4">
              <Card className="bg-primary">
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="fit-icon-2 text-info text-center">
                      <div style={{ width: 75, height: 75 }}>
                        <CircularProgressbar
                          styles={buildStyles({
                            textColor: "#c7cbd3",
                            textSize: "23px",
                            pathColor: `#c7cbd3`,
                            trailColor: "#fff",
                          })}
                          value={62}
                          text={"62%"}
                        />
                      </div>
                    </div>
                    <div className="ml-3">
                      <h5 className="text-white font-weight-bold">
                        180k <small> /300k Justification</small>
                      </h5>
                      <small className="mb-0">Justification</small>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            {(userData.role.name === "BUSINESS HEAD" ||
              userData.role.name === "PNL") && (
              <>
                <Col md="12" className="mb-4 mt-1">
                  <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <h4 className="font-weight-bold">Commission Milestone</h4>
                  </div>
                </Col>
                <Col md="3">
                  <Card>
                    <Card.Body>
                      <div className="align-items-center">
                        <div className="row">
                          <div className="col-lg-6">
                            <h5 className="mb-2 text-secondary mt-4 mr-3">
                              Level 1
                            </h5>
                          </div>
                          <div className="col-lg-6">
                            <div style={{ width: 75, height: 75 }}>
                              <CircularProgressbar
                                styles={buildStyles({
                                  textColor: "#c7cbd3",
                                  textSize: "23px",
                                  pathColor: `#FF2400`,
                                  trailColor: "#c7cbd3",
                                })}
                                value={62}
                                text={"62%"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <Card.Body>
                      <div className="align-items-center">
                        <div className="row">
                          <div className="col-lg-6">
                            <h5 className="mb-2 text-secondary mt-4 mr-3">
                              Level 2
                            </h5>
                          </div>
                          <div className="col-lg-6">
                            <div style={{ width: 75, height: 75 }}>
                              <CircularProgressbar
                                styles={buildStyles({
                                  textColor: "#c7cbd3",
                                  textSize: "23px",
                                  pathColor: `#FFBF00`,
                                  trailColor: "#c7cbd3",
                                })}
                                value={62}
                                text={"62%"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <Card.Body>
                      <div className="align-items-center">
                        <div className="row">
                          <div className="col-lg-6">
                            <h5 className="mb-2 text-secondary mt-4 mr-3">
                              Level 3
                            </h5>
                          </div>
                          <div className="col-lg-6">
                            <div style={{ width: 75, height: 75 }}>
                              <CircularProgressbar
                                styles={buildStyles({
                                  textColor: "#c7cbd3",
                                  textSize: "23px",
                                  pathColor: `#228B22	`,
                                  trailColor: "#c7cbd3",
                                })}
                                value={62}
                                text={"62%"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Top Deals</h4>
                  </div>
                  <div className="card-header-toolbar d-flex align-items-center">
                    <Dropdown>
                      <Dropdown.Toggle
                        href="#"
                        variant="text-muted pl-3"
                        id="dropdownMenuButton-customer"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          stroke="currentColor"
                          strokeWidth="2"
                          aria-hidden="true"
                          focusable="false"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <g
                            fill="none"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <circle cx="12" cy="12" r="1" />
                            <circle cx="19" cy="12" r="1" />
                            <circle cx="5" cy="12" r="1" />
                          </g>
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu aria-labelledby="dropdownMenuButton-customer">
                        <Dropdown.Item href="#">
                          <svg
                            className="svg-icon text-secondary"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                            />
                          </svg>
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <svg
                            className="svg-icon text-secondary"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                          </svg>
                          View
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <svg
                            className="svg-icon text-secondary"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Card.Header>
                <Card.Body className="p-0">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="table-color-heading">
                        <tr className="text-secondary">
                          <th scope="col">Date</th>
                          <th scope="col">Client Name</th>
                          <th scope="col">Project Name</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listArray &&
                          listArray.slice(0, 5).map((item) => (
                            <tr className="white-space-no-wrap">
                              <td>{item.date} </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div>{item.client}</div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div>Hedgeandsacks</div>
                                </div>
                              </td>
                              <td>
                                <p className="mb-0 text-success d-flex justify-content-start align-items-center">
                                  <small>
                                    <svg
                                      className="mr-2"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <circle
                                        cx="12"
                                        cy="12"
                                        r="8"
                                        fill="#3cb72c"
                                      ></circle>
                                    </svg>
                                  </small>{" "}
                                  Completed
                                </p>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-end align-items-center border-top-table p-3">
                      <button className="btn btn-secondary btn-sm">
                        See All
                      </button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashbord;
