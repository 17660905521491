import config from "../../config";
import * as api from "../../common/api";
import * as actionTypes from "../../constants/actionTypes";
import * as localstorage from "../../common/localStorage";
import axios from "axios";
const loginSuccess = (type, text) => ({
  type,
  payload: text,
});

const successMessage = (type, text) => ({
  type,
  payload: text,
});

const errorMessage = (type, text) => ({
  type,
  payload: text,
});

const statusInfo = (type) => ({
  type,
});

const setRoles = (type, text) => ({
  type,
  payload: text,
});

export const signout = () => (dispatch) => {
  dispatch({
    type: actionTypes.AUTH_ERROR,
  });
  dispatch(
    successMessage(actionTypes.SUCCESS_MESSAGE, "Signed Out Successfully")
  );
};

export const addIncentives = (payload) => async (dispatch) => {
  dispatch(statusInfo(actionTypes.RESET_STATUS));
  axios.defaults.headers.common[
    "Authorization"
  ] = `bearer ${localStorage.getItem("token")}`;
  var headers = {
    "Content-Type": "application/json",
  };
  return await axios
    .post(`${config.BASE_URL}incentives`, payload, { headers: headers })
    .then((response) => {
      // let { token, message, user } = response.data;
      // if (response.status == 200) {
      // localstorage.set("token", token);
      // localstorage.set("userdata", JSON.stringify(user));
      console.log("ppppopooopopop", response);
      dispatch(statusInfo(actionTypes.SUCCESS_STATUS));
      // dispatch(loginSuccess(actionTypes.USER_LOADED, response.data));
      // dispatch(successMessage(actionTypes.SUCCESS_MESSAGE, message));
      // }
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.AUTH_ERROR,
      });
      dispatch(
        errorMessage(actionTypes.ERROR_MESSAGE, error.response.data.message)
      );
      dispatch(statusInfo(actionTypes.ERROR_STATUS));
    });
};

export const getRoles = () => async (dispatch) => {
  dispatch(statusInfo(actionTypes.RESET_STATUS));
  axios.defaults.headers.common[
    "Authorization"
  ] = `bearer ${localStorage.getItem("token")}`;
  var headers = {
    "Content-Type": "application/json",
  };
  return await axios
    .get(`${config.BASE_URL}role`, { headers: headers })
    .then((response) => {
      dispatch(statusInfo(actionTypes.SUCCESS_STATUS));
      dispatch(setRoles(actionTypes.SET_ROLES, response.data));
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.AUTH_ERROR,
      });
      dispatch(
        errorMessage(actionTypes.ERROR_MESSAGE, error.response.data.message)
      );
      dispatch(statusInfo(actionTypes.ERROR_STATUS));
    });
};

export const userAddNew = (payload) => async (dispatch) => {
  dispatch(statusInfo(actionTypes.RESET_STATUS));
  axios.defaults.headers.common[
    "Authorization"
  ] = `bearer ${localStorage.getItem("token")}`;
  var headers = {
    "Content-Type": "application/json",
  };
  return await axios
    .post(`${config.BASE_URL}user`, payload, { headers: headers })
    .then((response) => {
      dispatch(statusInfo(actionTypes.SUCCESS_STATUS));
      dispatch(
        successMessage(actionTypes.SUCCESS_MESSAGE, "User Added Successfully")
      );
      return true;
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.AUTH_ERROR,
      });
      dispatch(
        errorMessage(actionTypes.ERROR_MESSAGE, error.response.data.message)
      );
      dispatch(statusInfo(actionTypes.ERROR_STATUS));
      return false;
    });
};
