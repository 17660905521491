import { RESET_STATUS, SUCCESS_STATUS, ERROR_STATUS } from "../../constants/actionTypes";

const initialState = {
  isFetching:false,
  isFetched:false,
  isError:false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_STATUS:
      return {
        isFetching:true,
        isFetched:false,
        isError:false
      };
      case SUCCESS_STATUS:
      return {
        isFetching:false,
        isFetched:true,
        isError:false
      };
      case ERROR_STATUS:
      return {
        isFetching:false,
        isFetched:false,
        isError:true
      };
    default:
      return state;
  }
}
