import React, { useEffect, useState } from "react";
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import Card from "../../../../components/Card";
import { Link } from "react-router-dom";
import {
  getUsers,
  getSlab,
  setSlab,
} from "../../../../actions/userAction/userAction";
import avatar from "../../../../assets/images/avatar-3.png";
import { useDispatch, useSelector } from "react-redux";

const MySlab = () => {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const userList = useSelector((state) => state.userReducer.users);
  const slab = useSelector((state) => state.userReducer.users);
  const slabArray = useSelector((state) => state.slabReducer.seperateSlab);
  const roles = useSelector((state) => state.rolesReducer.roles);
  let indArray = [];
  useEffect(() => {
    roles.forEach(async (item) => {
      if (item.name === userData.role.name) {
        indArray = await dispatch(getSlab({ roleId: item.id }));
        dispatch(setSlab("GET_INDIVIDUAL_ARRAY", indArray));
      }
    });
    // dispatch(getUsers("Customer"));
  }, []);

  const [q, setQ] = useState("");
  const [searchTerm] = useState(["email", "firstname", "lastname"]);

  const handleSearch = (items) => {
    return items.filter((item) => {
      return searchTerm.some((newItem) => {
        return (
          item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
  };

  const listSlab = [
    { range: "0 - 50000 AED", percent: "5%" },
    { range: "50,000 AED - 100,000 AED", percent: "8%" },
    { range: "100,000 AED and above", percent: "12%" },
  ];
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="font-weight-bold">My Slab</h4>
              </div>
            </div>
            <Row>
              <Col lg="5">
                <Card className="card-block">
                  <Card.Header className="bg-primary">
                    <h5 style={{ color: "white" }}>Revenue</h5>
                  </Card.Header>
                  <Card.Body className="p-0">
                    <div className="table-responsive">
                      <table className="table data-table mb-0">
                        <thead className="table-color-heading">
                          <tr className="">
                            <th scope="col">Range</th>
                            <th scope="col">Commission (in %)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {slabArray.map((item, index) => {
                            return (
                              <>
                                {item.commission_type === "revenue" && (
                                  <tr key={`${index}pnlslab`}>
                                    <td>
                                      {item.upperlimit} - {item.lowerlimit}
                                    </td>
                                    <td>{item.rate}%</td>
                                  </tr>
                                )}
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="5">
                <Card className="card-block">
                  <Card.Body className="p-0">
                    <Card.Header className="bg-primary">
                      <h5 style={{ color: "white" }}>Spot</h5>
                    </Card.Header>
                    <div className="table-responsive">
                      <table className="table data-table mb-0">
                        <thead className="table-color-heading">
                          <tr className="">
                            <th scope="col">Range</th>
                            <th scope="col">Commission (in %)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {slabArray.map((item, index) => {
                            return (
                              <>
                                {item.commission_type === "spot" && (
                                  <tr key={`${index}pnlslab`}>
                                    <td>
                                      {item.upperlimit} - {item.lowerlimit}
                                    </td>
                                    <td>{item.rate}%</td>
                                  </tr>
                                )}
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default MySlab;
