import React from "react";
import FooterStyle from "../components/partials/backend/FooterStyle/FooterStyle";
import HeaderStyle1 from "../components/partials/backend/HeaderStyle/HeaderStyle1";

// Router Component
import SidebarBusinessHeadCustom from "../components/partials/backend/SidebarBusinessHeadCustom/SidebarBusinessHeadCustom";
import { useLocation } from "react-router-dom";

import Dashbord from "../views/backend/Main/Dashbord";
import { useDispatch } from "react-redux";

import MyTeam from "../views/backend/Main/BusinessHead/TeamInfo/MyTeam";
import ViewTeam from "../views/backend/Main/BusinessHead/TeamInfo/ViewTeam";
import MyClients from "../views/backend/Main/BusinessHead/Clients/MyClients";
import BusinessReports from "../views/backend/Main/BusinessHead/BusinessReports";
import ListDeal from "../views/backend/Main/BusinessHead/Deal/ListDeal";
import ViewDeal from "../views/backend/Main/BusinessHead/Deal/ViewDeal";
import AddDeal from "../views/backend/Main/BusinessHead/Deal/AddDeal";
import ListBusiness from "../views/backend/Main/BusinessHead/BusinessInfo/ListBuiness";
import ViewBusiness from "../views/backend/Main/BusinessHead/BusinessInfo/ViewBusiness";
import MySlab from "../views/backend/Main/BusinessHead/MySlab";
import SidebarAdmin from "../components/partials/backend/SidebarAdmin/SidebarAdmin";
import AdminEmployee from "../views/backend/Main/Admin/Employee/AdminEmployee";
import ViewAdminEmployee from "../views/backend/Main/Admin/Employee/ViewAdminEmployee";
import AdminTeam from "../views/backend/Main/Admin/Team/AdminTeam";
import ViewAdminTeam from "../views/backend/Main/Admin/Team/ViewAdminTeam";
import ViewAdminSlab from "../views/backend/Main/Admin/Slab/ViewAdminSlab";
import AddAdminSlab from "../views/backend/Main/Admin/Slab/AddAdminSlab";
import AdminCases from "../views/backend/Main/Admin/Cases/AdminCases";
import ViewAdminCases from "../views/backend/Main/Admin/Cases/ViewAdminCases";
import AddIncentives from "../views/backend/Main/Admin/Incentive/AddIncentives";
import DirectorEmployee from "../views/backend/Main/BusinessHead/Employee/DirectorEmployee";
import ViewDirectorEmployee from "../views/backend/Main/BusinessHead/Employee/ViewDirectorEmployee";
import AddRoles from "../views/backend/Main/SuperAdmin/AddRoles";
import CompanyFixedCost from "../views/backend/Main/CompanyFixedCost/CompanyFixedCost";
import ClientDetails from "../views/backend/Main/BusinessHead/Clients/ClientDetails";
import DirectorDashbord from "../views/backend/Main/DirectorDashboard";

const PankajLayout = () => {
  let path = useLocation().pathname;
  path = path.split("/")[1];

  const dispatch = useDispatch();

  const userData = JSON.parse(localStorage.getItem("userdata"));

  const getComponent = (path) => {
    switch (path) {
      case "my-team":
        return <MyTeam />;
      case "view-team":
        return <ViewTeam />;
      case "my-clients":
        return <MyClients />;
      case "reports":
        return <BusinessReports />;
      case "list-deal":
        return <ListDeal />;
      case "view-deal":
        return <ViewDeal />;
      case "add-deal":
        return <AddDeal />;
      case "list-business":
        return <ListBusiness />;
      case "view-business":
        return <ViewBusiness />;
      case "my-slab":
        return <MySlab />;
      case "list-admin-employee":
        return <AdminEmployee />;
      case "view-admin-employee":
        return <ViewAdminEmployee />;
      case "list-admin-team":
        return <AdminTeam />;
      case "view-admin-team":
        return <ViewAdminTeam />;
      case "view-admin-slab":
        return <ViewAdminSlab />;
      case "add-admin-slab":
        return <AddAdminSlab />;
      case "admin-cases":
        return <AdminCases />;
      case "view-admin-cases":
        return <ViewAdminCases />;
      case "add-incentives":
        return <AddIncentives />;
      case "director-view-employee":
        return <DirectorEmployee />;
      case "view-director-employee":
        return <ViewDirectorEmployee />;
      case "add-user-data":
        return <AddRoles />;
      case "company-fixed-cost":
        return <CompanyFixedCost />;
      case "client-details":
        return <ClientDetails />;
      default:
        <ClientDetails />;
        if (userData && userData.role.name == "DIRECTOR") {
          return <DirectorDashbord user={userData} />;
        } else if (userData && userData.role.name == "BUSINESS HEAD") {
          return <Dashbord user={userData} />;
        }
        return <Dashbord />;
    }
  };
  return (
    <>
      <div className="wrapper">
        <HeaderStyle1 user={userData} />
        {userData &&
          (userData.role.name === "BUSINESS HEAD" ||
            userData.role.name === "PNL" ||
            userData.role.name === "DIRECTOR") && (
            <>
              <SidebarBusinessHeadCustom user={userData} />
            </>
          )}
        {userData &&
          (userData.role.name === "ADMIN" ||
            userData.role.name === "SuperAdmin") && (
            <>
              <SidebarAdmin user={userData} />
            </>
          )}

        <div className="content-page">{getComponent(path)}</div>
      </div>
      <FooterStyle />
    </>
  );
};

export default PankajLayout;
