import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { Container, Row, Col, Form, Button, ListGroup } from "react-bootstrap";
import Card from "../../../../../components/Card";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getRoles,
  userAddNew,
  addIncentives,
} from "../../../../../actions/AdminAction/adminAction";
import { addSlab } from "../../../../../actions/userAction/userAction";
const AddAdminSlab = (props) => {
  // get customer data
  const location = useLocation();

  const history = useHistory();

  const dispatch = useDispatch();
  const roles = useSelector((state) => state.rolesReducer.roles);
  const validationSchema = Yup.object().shape({
    roleId: Yup.string().required("Required"),
    upperlimit: Yup.string().required("Required"),
    lowerlimit: Yup.string().required("Required"),
    rate: Yup.string().required("Required"),
    commission_type: Yup.string().required("Required"),
    // product_type: Yup.string().required("Required"),
  });
  const commissionType = [
    { name: "Revenue", value: "revenue", id: 1 },
    { name: "Spot", value: "spot", id: 2 },
  ];

  useEffect(() => {
    dispatch(getRoles());
  }, []);

  const submitSlabInfo = async (e) => {};

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="d-flex flex-wrap align-items-center justify-content-end">
              <Link
                to="/list-deal"
                className="btn btn-primary btn-sm d-flex align-items-center justify-content-between"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2">Back</span>
              </Link>
            </div>
          </Col>
          <Col lg="12" className="mb-3 d-flex justify-content-between">
            <h4 className="font-weight-bold0 d-flex align-items-center">
              Add Slab
            </h4>
          </Col>
        </Row>

        <Card className="col-md-10 col-sm-12">
          <div className="col-md-10 col-sm-12" style={{ padding: "20px" }}>
            <Formik
              validationSchema={validationSchema}
              onSubmit={(values) => {
                dispatch(addSlab(values));
              }}
              initialValues={{
                rate: null,
                lowerlimit: null,
                upperlimit: null,
                roleId: null,
                commission_type: null,
                // product_type: null,
              }}
            >
              {({ handleSubmit, handleChange, touched, errors, values }) => (
                <Col lg="12">
                  <Card>
                    <Card.Body>
                      <Form noValidate onSubmit={handleSubmit}>
                        <Row>
                          <Col md="12">
                            <Card.Header>
                              <Card.Header.Title>
                                <h4 className="card-title">Add Slab</h4>
                              </Card.Header.Title>
                            </Card.Header>
                            <Card.Body>
                              <div className="new-user-info">
                                <Row>
                                  <div className="col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="">Lower Limit</label>
                                      <Field
                                        type="text"
                                        className={`form-control ${
                                          touched.lowerlimit &&
                                          errors.lowerlimit
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="Enter Range Start"
                                        name="lowerlimit"
                                      />

                                      <ErrorMessage
                                        className="invalid-feedback"
                                        name="lowerlimit"
                                        component="span"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="">Upper Limit</label>
                                      <Field
                                        type="text"
                                        className={`form-control ${
                                          touched.upperlimit &&
                                          errors.upperlimit
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="Enter Range End"
                                        name="upperlimit"
                                      />

                                      <ErrorMessage
                                        className="invalid-feedback"
                                        name="upperlimit"
                                        component="span"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="">Commission</label>
                                      <Field
                                        type="text"
                                        className={`form-control ${
                                          touched.rate && errors.rate
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="Enter Commision"
                                        name="rate"
                                      />

                                      <ErrorMessage
                                        className="invalid-feedback"
                                        name="rate"
                                        component="span"
                                      />
                                    </div>
                                  </div>
                                  {/* <div className="col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="">Product Type</label>
                                      <Field
                                        as="select"
                                        className={`form-control ${
                                          touched.product_type &&
                                          errors.product_type
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="Enter Incentives Amount"
                                        name="product_type"
                                      >
                                        <option disabled>
                                          Select Product Type
                                        </option>
                                        {productType.map((data, index) => {
                                          return (
                                            <>
                                              <option value={data.name}>
                                                {data.name}
                                              </option>
                                            </>
                                          );
                                        })}
                                      </Field>
                                      <ErrorMessage
                                        className="invalid-feedback"
                                        name="product_type"
                                        component="span"
                                      />
                                    </div>
                                  </div> */}
                                  <div className="col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="">Commission Type</label>
                                      <Field
                                        as="select"
                                        className={`form-control ${
                                          touched.commission_type &&
                                          errors.commission_type
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="Enter Commission Type"
                                        name="commission_type"
                                      >
                                        <option disabled>
                                          Select Product Type
                                        </option>
                                        {commissionType.map((data, index) => {
                                          return (
                                            <>
                                              <option value={data.name}>
                                                {data.name}
                                              </option>
                                            </>
                                          );
                                        })}
                                      </Field>
                                      <ErrorMessage
                                        className="invalid-feedback"
                                        name="product_type"
                                        component="span"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="">Roles</label>
                                      <Field
                                        as="select"
                                        className={`form-control ${
                                          touched.customerName &&
                                          errors.customerName
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="Enter Employee Id"
                                        name="roleId"
                                      >
                                        <option disabled>Select role Id</option>
                                        {roles.map((data, index) => {
                                          return (
                                            <>
                                              <option value={data.id}>
                                                {data.name}
                                              </option>
                                            </>
                                          );
                                        })}
                                      </Field>
                                      <ErrorMessage
                                        className="invalid-feedback"
                                        name="roleId"
                                        component="span"
                                      />
                                    </div>
                                  </div>
                                </Row>
                                <div className="d-flex justify-content-end mt-3">
                                  <Button
                                    type="submit"
                                    variant="btn btn-primary"
                                  >
                                    Add Slab
                                  </Button>
                                </div>
                              </div>
                            </Card.Body>
                          </Col>
                        </Row>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Formik>
          </div>
        </Card>
      </Container>
    </>
  );
};

export default AddAdminSlab;
