module.exports = {
  USER_LOADED: "USER_LOADED",
  AUTH_ERROR: "AUTH_ERROR",
  SUCCESS_MESSAGE: "SUCCESS_MESSAGE",
  ERROR_MESSAGE: "ERROR_MESSAGE",
  WARNING_MESSAGE: "WARNING_MESSAGE",
  INFO_MESSAGE: "INFO_MESSAGE",
  GET_USERS: "GET_USERS",
  GET_ACCOUNTS: "GET_ACCOUNTS",
  GET_TRANSACTIONS: "GET_TRANSACTIONS",
  GET_FEEDBACK: "GET_FEEDBACK",
  LOADING_TOGGLE: "LOADING_TOGGLE",
  GET_EMP_USERS: "GET_EMP_USERS",
  GET_USER_TYPE: "GET_USER_TYPE",
  GET_DOCUMENTS: "GET_DOCUMENTS",
  RESET_STATUS: "RESET_STATUS",
  SUCCESS_STATUS: "SUCCESS_STATUS",
  ERROR_STATUS: "ERROR_STATUS",
  SET_ROLES: "SET_ROLES",
  LIST_USER: "LIST_USER",
  TEAM_LIST: "TEAM_LIST",
  GET_BUSSINESS_ARRAY: "GET_BUSSINESS_ARRAY",
  GET_PNL_ARRAY: "GET_PNL_ARRAY",
  GET_T3_ARRAY: "GET_T3_ARRAY",
  GET_T2_ARRAY: "GET_T2_ARRAY",
  GET_T1_ARRAY: "GET_T1_ARRAY",
  GET_T0_ARRAY: "GET_T0_ARRAY",
  GET_INDIVIDUAL_ARRAY: "GET_INDIVIDUAL_ARRAY",
  LIST_CASES: "LIST_CASES",
};
