import React, { useEffect, useState, useRef } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory, useLocation, Link } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Card from "../../../../components/Card";
import { useDispatch, useSelector } from "react-redux";
import {
  getRoles,
  userAddNew,
} from "../../../../actions/AdminAction/adminAction";
import { getUser } from "../../../../actions/userAction/userAction";

const AddRoles = (props) => {
  const location = useLocation();
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    roleId: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
  });

  const userData = JSON.parse(localStorage.getItem("userdata"));

  const roles = useSelector((state) => state.rolesReducer.roles);
  const userList = useSelector((state) => state.userReducer.users);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRoles());
    dispatch(getUser());
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="d-flex flex-wrap align-items-center justify-content-end">
              <Link
                to="/"
                className="btn btn-primary btn-sm d-flex align-items-center justify-content-between"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2">Back</span>
              </Link>
            </div>
          </Col>
          <Col lg="12" className="mb-3 d-flex justify-content-between">
            <h4 className="font-weight-bold0 d-flex align-items-center">
              Add User
            </h4>
          </Col>
          <Formik
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              userList.forEach((data) => {
                if (values.manager_id == data.id) {
                  values["manager_name"] = data.name;
                }
              });
              const result = await dispatch(userAddNew(values));
              if (result) {
                history.push("/");
              }
            }}
            enableReinitialize={true}
            initialValues={{
              roleId: "",
              email: "",
              password: "",
              name: "",
              emp_id: "",
              manager_id: null,
              phoneno: "",
              dob: "",
            }}
          >
            {({ handleSubmit, handleChange, touched, errors, values }) => (
              <Col lg="12">
                <Card>
                  <Card.Body>
                    <Form noValidate onSubmit={handleSubmit}>
                      <Row>
                        <Col md="9">
                          <Card.Header>
                            <Card.Header.Title>
                              <h4 className="card-title">
                                New User Information
                              </h4>
                            </Card.Header.Title>
                          </Card.Header>
                          <Card.Body>
                            <div className="new-user-info">
                              <Row>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">User Role</label>
                                    <Field
                                      as="select"
                                      className="form-control"
                                      name="roleId"
                                    >
                                      <option>Select Role</option>
                                      {roles.map((data) => {
                                        return (
                                          <option value={data.id}>
                                            {data.name}
                                          </option>
                                        );
                                      })}
                                    </Field>
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Name</label>
                                    <Field
                                      type="text"
                                      className={`form-control ${
                                        touched.name && errors.name
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Enter Name"
                                      name="name"
                                    />
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="name"
                                      component="span"
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Email</label>
                                    <Field
                                      type="email"
                                      className={`form-control ${
                                        touched.email && errors.email
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Email"
                                      name="email"
                                    />
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="email"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Password</label>
                                    <Field
                                      type="text"
                                      className={`form-control ${
                                        touched.password && errors.password
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Enter Password"
                                      name="password"
                                    />
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="password"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Select Manager</label>
                                    <Field
                                      as="select"
                                      className="form-control"
                                      name="manager_id"
                                    >
                                      <option>Select Manager</option>
                                      {userList.map((data) => {
                                        return (
                                          <option value={data.id}>
                                            {data.name}
                                          </option>
                                        );
                                      })}
                                    </Field>
                                  </div>
                                </div>
                                <Button
                                  type="submit"
                                  variant="btn btn-primary btn-block mt-2"
                                >
                                  Add User
                                </Button>
                              </Row>
                            </div>
                          </Card.Body>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Formik>
        </Row>
      </Container>
    </>
  );
};

export default AddRoles;
