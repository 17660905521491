import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import Card from "../../../../../components/Card";
import { Link } from "react-router-dom";
import avatar from "../../../../../assets/images/avatar-3.png";
import Multiselect from "multiselect-react-dropdown";
import { useDispatch, useSelector } from "react-redux";

const AdminEmployee = () => {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const userList = useSelector((state) => state.userReducer.users);

  const [deleteUser, setDeleteUser] = useState({});
  const [show, setShow] = useState(false);
  const [showAddTeam, setShowAddTeam] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const [options, setOptions] = useState([
    { name: "Option 1", id: 1 },
    { name: "Option 2", id: 2 },
    { name: "Option 3", id: 3 },
    { name: "Option 4", id: 4 },
    { name: "Option 5", id: 5 },
    { name: "Option 6", id: 6 },
    { name: "Option 7", id: 7 },
  ]);

  const listArray = [
    {
      id: 1,
      name: "Pankaj Joshi",
      email: "joshi7621@gmail.com",
      designation: "T2",
      phone: "9898989898",
      active: true,
    },
    {
      id: 2,
      name: "Dheeraj Joshi",
      email: "d_m@gmail.com",
      designation: "T3",
      phone: "9898989898",
      active: true,
    },
  ];

  const handleClose = () => {
    setShow(false);
    setShowAddTeam(false);
  };
  const handleShow = (id, firstname, lastname) => {
    setShow(true);
    setDeleteUser({ id, firstname, lastname });
  };

  const handleSave = () => {};
  const [q, setQ] = useState("");
  const [searchTerm] = useState(["email", "firstname", "lastname"]);

  const handleSearch = (items) => {
    return items.filter((item) => {
      return searchTerm.some((newItem) => {
        return (
          item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
  };

  const onSelect = () => {};

  const onRemove = () => {};
  return (
    <>
      <Modal size="lg" show={showAddTeam} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            User : {deleteUser.firstname} {deleteUser.lastname}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Multiselect
            options={options} // Options to display in the dropdown
            selectedValues={selectedValue} // Preselected value to persist in dropdown
            onSelect={onSelect} // Function will trigger on select event
            onRemove={onRemove} // Function will trigger on remove event
            displayValue="name" // Property name to display in the dropdown options
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button
            variant="danger"
            className="warning"
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="font-weight-bold">Employees</h4>
              </div>
              <div className="create-workform">
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div className="modal-product-search d-flex">
                    <Form className="mr-3 position-relative">
                      <Form.Group className="mb-0">
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="exampleInputText"
                          placeholder="Search Employee"
                          value={q}
                          onChange={(e) => setQ(e.target.value)}
                        />
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
            <Row>
              <Col lg="12">
                <Card className="card-block card-stretch">
                  <Card.Body className="p-0">
                    <div className="d-flex justify-content-between align-items-center p-3">
                      <h5 className="font-weight-bold">Employees List</h5>
                    </div>
                    <div className="table-responsive">
                      <table className="table data-table mb-0">
                        <thead className="table-color-heading">
                          <tr className="">
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Designation</th>
                            <th scope="col">Phone</th>
                            <th scope="col" className="text-right">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {userList &&
                            userList.map((item) => (
                              <tr key={item.id} className="white-space-no-wrap">
                                <td className="">
                                  <div className="active-project-1 d-flex align-items-center mt-0 ">
                                    <div className="h-avatar is-medium">
                                      <img
                                        className="avatar rounded-circle"
                                        alt="user-icon"
                                        src={item.image || avatar}
                                      />
                                    </div>
                                    <div className="data-content">
                                      <div>
                                        <span className="font-weight-bold">
                                          {item.name}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>{item.email}</td>
                                <td>{item.designation}</td>
                                <td>{item.phone}</td>
                                <td>
                                  <div className="d-flex justify-content-end align-items-center">
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip>View</Tooltip>}
                                    >
                                      <Link
                                        className="mr-4"
                                        to={{
                                          pathname: `/view-admin-employee/${item.id}`,
                                          state: { data: item },
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="text-secondary"
                                          width="20"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                          />
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                          />
                                        </svg>
                                      </Link>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip>Delete</Tooltip>}
                                    >
                                      <Button
                                        className="badge bg-danger"
                                        onClick={() =>
                                          handleShow(
                                            item.id,
                                            item.firstname,
                                            item.lastname
                                          )
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                          />
                                        </svg>
                                      </Button>
                                    </OverlayTrigger>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default AdminEmployee;
