import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import Chart from "react-apexcharts";
import * as Yup from "yup";
import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  InputGroup,
  Badge,
} from "react-bootstrap";
import UiBadges from "../../../ui/UiBadges";
import Card from "../../../../../components/Card";
import { Link, useParams, useLocation } from "react-router-dom";
// img
import avatar from "../../../../../assets/images/avatar-3.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const ViewAdminEmployee = (props) => {
  const history = useHistory();
  const selectedEmployee = history.location.state.data;

  const userData = JSON.parse(localStorage.getItem("userdata"));

  const dispatch = useDispatch();

  const location = useLocation();

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-between">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb p-0 mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/customer">Employee</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Employee Detail View
                    </li>
                  </ol>
                </nav>
              </div>
              <Link
                to="/list-admin-employee"
                className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2">Back</span>
              </Link>
            </div>
          </Col>
          <Col lg="12" className="mb-3 d-flex justify-content-between">
            <h4 className="font-weight-bold d-flex align-items-center">
              Employee Detail View
            </h4>
          </Col>
        </Row>

        <Row>
          <Col lg="8">
            <Card>
              <ListGroup as="ul" className="list-group-flush">
                <ListGroup.Item as="li">
                  <div>
                    <ListGroup as="ul" className="list-style-1 mb-0">
                      <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-start align-items-center"
                      >
                        <div className="avatar">
                          <img
                            className="avatar avatar-img avatar-60 rounded-circle"
                            src={avatar}
                            alt="01.jpg"
                          />
                        </div>
                        <div className="list-style-detail ml-4 mr-2">
                          <h5 className="font-weight-bold">
                            {selectedEmployee.name}
                          </h5>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <table className="table table-borderless mb-0">
                    <tbody>
                      <tr>
                        <td className="p-0">
                          <h4 className="mb-0 text-muted">Salary</h4>
                        </td>
                        <td>
                          <h4
                            style={{ float: "right" }}
                            className="mb-0 text-success d-flex justify-content-start align-items-center"
                          >
                            {selectedEmployee.salary.name}
                          </h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <table className="table table-borderless mb-0">
                    <tbody>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Email ID</p>
                        </td>
                        <td>
                          <p className="mb-0 ">{selectedEmployee.email}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Birthday</p>
                        </td>
                        <td>
                          <p className="mb-0 ">{selectedEmployee.dob}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Phone</p>
                        </td>
                        <td>
                          <p className="mb-0 ">{selectedEmployee.phoneno}</p>
                        </td>
                      </tr>
                      {/* <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Country</p>
                        </td>
                        <td>
                          <p className="mb-0 ">UAE</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">State/Region</p>
                        </td>
                        <td>
                          <p className="mb-0 ">Dubai</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Address</p>
                        </td>
                        <td>
                          <p className="mb-0 ">Dubai</p>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ViewAdminEmployee;
