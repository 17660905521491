import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Accordion, Button } from "react-bootstrap";
import Scrollbar from "smooth-scrollbar";
import { connect } from "react-redux";
import { getDarkMode } from "../../../../store/mode";

import { getRoles } from "../../../../actions/AdminAction/adminAction";
//img
import logo from "../../../../../src/assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";

function mapStateToProps(state) {
  return {
    darkMode: getDarkMode(state),
  };
}

const minisidbar = () => {
  document.body.classList.toggle("sidebar-main");
};

const SidebarStyle = (props) => {
  //location
  let location = useLocation();
  const dispatch = useDispatch();

  const userData = props.user;

  const urlParams = new URLSearchParams(window.location.search);
  const sidebar = urlParams.get("sidebar");
  var variant = "";
  if (sidebar !== null) {
    variant = "";
    switch (sidebar) {
      case "0":
        variant = "sidebar-dark";
        break;
      case "1":
        variant = "sidebar-light";
        break;
      default:
        variant = "";
        break;
    }
  }

  // Collapse state
  const [activeMenu, setActiveMenu] = useState(false);
  const [activesubMenu, setSubmenu] = useState(false);
  useEffect(() => {
    Scrollbar.init(document.querySelector("#my-scrollbar"));
  });
  useEffect(() => {
    dispatch(getRoles());
  }, []);
  return userData ? (
    <>
      <div className={`iq-sidebar sidebar-default ${variant}`}>
        <div className="iq-sidebar-logo d-flex align-items-end justify-content-between">
          <Link to="/" className="header-logo">
            <img
              src={logo}
              className={`img-fluid rounded-normal light-logo ${
                props.darkMode ? "d-none" : ""
              }`}
              alt="logo"
            />
            <span>Hedge&Sachs</span>
          </Link>
          <div className="side-menu-bt-sidebar-1">
            <svg
              onClick={minisidbar}
              xmlns="http://www.w3.org/2000/svg"
              className="text-light wrapper-menu"
              width="30"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
        <div className="data-scrollbar" data-scroll="1" id="my-scrollbar">
          <nav className="iq-sidebar-menu">
            <Accordion
              as="ul"
              id="iq-sidebar-toggle"
              className="side-menu"
              onSelect={(e) => setActiveMenu(e)}
            >
              <li
                className={`${
                  location.pathname === "/" ? "active" : ""
                }  sidebar-layout`}
              >
                <Link to="/" className="svg-icon">
                  <i className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                      />
                    </svg>
                  </i>
                  <span className="ml-2">Dashboard</span>
                  <p className="mb-0 w-10 badge badge-pill badge-primary">6</p>
                </Link>
              </li>
              <li className="px-3 pt-3 pb-2 ">
                <span className="text-uppercase small font-weight-bold">
                  Application
                </span>
              </li>
              {/* {props.user.role.name === "DIRECTOR"  && ( */}
              <li
                className={`${
                  location.pathname === "/my-team" ? "active" : ""
                }  sidebar-layout`}
              >
                <Link to="/my-team" className="svg-icon ">
                  <i className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                      />
                    </svg>
                  </i>
                  <span className="ml-2">Team</span>
                </Link>
              </li>
              {/* )} */}
              <li
                className={`${
                  location.pathname === "/my-clients" ? "active" : ""
                }  sidebar-layout`}
              >
                <Link to="/my-clients" className="svg-icon">
                  <i className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                      />
                    </svg>
                  </i>
                  <span className="ml-2">Clients</span>
                </Link>
              </li>
              <li
                className={`${
                  location.pathname === "/reports" ? "active" : ""
                }  sidebar-layout`}
              >
                <Link to="reports" className="svg-icon">
                  <i className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                      />
                    </svg>
                  </i>
                  <span className="ml-2">Reports</span>
                </Link>
              </li>
              <li
                className={`${
                  location.pathname === "/list-deal" ? "active" : ""
                }  sidebar-layout`}
              >
                <Link to="/list-deal" className="svg-icon">
                  <i className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                      />
                    </svg>
                  </i>
                  <span className="ml-2">Cases</span>
                </Link>
              </li>
              {props.user.role.name === "DIRECTOR" && (
                <>
                  <li
                    className={`${
                      location.pathname === "/director-view-employee"
                        ? "active"
                        : ""
                    }  sidebar-layout`}
                  >
                    <Link to="/director-view-employee" className="svg-icon">
                      <i className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                          />
                        </svg>
                      </i>
                      <span className="ml-2">Employees</span>
                    </Link>
                  </li>
                  {/* <li
                    className={`${
                      location.pathname === "/list-business" ? "active" : ""
                    }  sidebar-layout`}
                  >
                    <Link to="/list-business" className="svg-icon">
                      <i className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                          />
                        </svg>
                      </i>
                      <span className="ml-2">Buisness Head</span>
                    </Link>
                  </li> */}
                </>
              )}

              {props.user.role.name !== "DIRECTOR" && (
                <li
                  className={`${
                    location.pathname === "/my-slab" ? "active" : ""
                  }  sidebar-layout`}
                >
                  <Link to="/my-slab" className="svg-icon">
                    <i className="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                        />
                      </svg>
                    </i>
                    <span className="ml-2">Slab</span>
                  </Link>
                </li>
              )}
            </Accordion>
          </nav>
        </div>
      </div>
    </>
  ) : null;
};

// export default SidebarStyle;
export default connect(mapStateToProps)(SidebarStyle);
