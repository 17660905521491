import React, { useEffect } from "react";
// import { Link } from 'react-router-dom'
import Chart from "react-apexcharts";

import moment from "moment";

//leaflet
import Leaflet from "../../../components/leaflet";

//datepicker
import Datepickers from "../../../components/Datepicker";

//circularpro
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  Card,
  Container,
  Row,
  Col,
  Dropdown,
  Button,
  Popover,
  OverlayTrigger,
  // Popover,
  // PopoverBody,
} from "react-bootstrap";

//api
import { getUser, getAllCase } from "../../../actions/userAction/userAction";

import { useDispatch, useSelector } from "react-redux";

const DirectorDashbord = () => {
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.userReducer.users);
  const newUserList = userList.filter(
    (item) => item.salary?.amount !== null && item.role.name !== "SuperAdmin"
  );
  const month = [
    { name: "January", id: 1 },
    { name: "February", id: 2 },
    { name: "March", id: 3 },
    { name: "April", id: 4 },
    { name: "May", id: 5 },
    { name: "June", id: 6 },
    { name: "July", id: 7 },
    { name: "August", id: 8 },
    { name: "September", id: 9 },
    { name: "October", id: 10 },
    { name: "November", id: 11 },
    { name: "December", id: 12 },
  ];
  const casesList = useSelector((state) => state.caseReducer.casesList);
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const listArray = [
    {
      date: "19 may 2022",
      project: "hedgeandsacks",
      client: "Jhon Doe",
      status: "Pending",
    },
  ];
  useEffect(() => {
    dispatch(getAllCase());
    dispatch(getUser());
  }, []);

  let revenue = 0;
  let salary = 0;
  let quantam = 0;
  casesList.map((item) => (revenue = revenue + item.deal_revenue));
  casesList.map((item) => (quantam = quantam + parseFloat(item.h_quantam)));
  newUserList.map((item) => (salary = salary + item?.salary?.amount));

  const totalRevenuePopover = (
    <Popover id="popover-basic">
      <Popover.Content>Total Revenue in current month</Popover.Content>
    </Popover>
  );
  const totalQuantamPopover = (
    <Popover id="popover-basic">
      <Popover.Content>Total Quantam in current month</Popover.Content>
    </Popover>
  );
  const commissionPopover = (
    <Popover id="popover-basic">
      <Popover.Content>
        And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
  );
  const unitSoldPopover = (
    <Popover id="popover-basic">
      <Popover.Content>Total Units Sold in current month</Popover.Content>
    </Popover>
  );
  const incentivePopover = (
    <Popover id="popover-basic">
      <Popover.Content>Total team incentive in current month</Popover.Content>
    </Popover>
  );
  const fixedCostPopover = (
    <Popover id="popover-basic">
      <Popover.Content>Comapny cost in current month</Popover.Content>
    </Popover>
  );
  const teamSalaryPopover = (
    <Popover id="popover-basic">
      <Popover.Content>Total team's salary</Popover.Content>
    </Popover>
  );
  const JustificationPopover = (
    <Popover id="popover-basic">
      <Popover.Content>
        And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
  );
  const totalSalaryPopover = (
    <Popover id="popover-basic">
      <Popover.Content>
        And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
  );
  const pnlPopover = (
    <Popover id="popover-basic">
      <Popover.Content>
        And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
  );

  return (
    <Container fluid>
      <Row>
        <Col md="12" className="mb-4 mt-1">
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <h4 className="font-weight-bold">Current Month Overview</h4>
            <div className="form-group mb-0 vanila-daterangepicker d-flex flex-row">
              <div>
                <select
                  className="form-select form-control"
                  onSelect={() => {
                    console.log("hlos");
                  }}
                >
                  {month.map((data) => {
                    return (
                      <option
                        value={data.id}
                        selected={new Date().getMonth() + 1 === data.id}
                      >
                        {data.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </Col>

        <Col lg="12" md="12">
          <Row>
            <OverlayTrigger
              trigger="click"
              placement="top"
              overlay={totalQuantamPopover}
            >
              <Col md="4">
                <Card>
                  <Card.Body>
                    <div className="d-flex align-items-center">
                      <div className="">
                        <p className="mb-2 text-secondary">Total Quantam</p>
                        <div className="d-flex flex-wrap justify-content-start align-items-center">
                          <h5 className="mb-0 font-weight-bold">
                            {quantam.toLocaleString("en-US")} AED
                          </h5>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </OverlayTrigger>
            <OverlayTrigger
              trigger="click"
              placement="top"
              overlay={totalRevenuePopover}
            >
              <Col md="4">
                <Card>
                  <Card.Body>
                    <div className="d-flex align-items-center">
                      <div className="">
                        <p className="mb-2 text-secondary">Total Revenue</p>
                        <div className="d-flex flex-wrap justify-content-start align-items-center">
                          <h5 className="mb-0 font-weight-bold">
                            {revenue.toLocaleString("en-US")} AED
                          </h5>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </OverlayTrigger>
            <OverlayTrigger
              trigger="click"
              placement="top"
              overlay={unitSoldPopover}
            >
              <Col md="3">
                <Card>
                  <Card.Body>
                    <div className="d-flex align-items-center">
                      <div className="">
                        <p className="mb-2 text-secondary">
                          Total Real Estate Deals
                        </p>
                        <div className="d-flex flex-wrap justify-content-start align-items-center">
                          <h5 className="mb-0 font-weight-bold">0</h5>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </OverlayTrigger>
            <OverlayTrigger
              trigger="click"
              placement="top"
              overlay={unitSoldPopover}
            >
              <Col md="3">
                <Card>
                  <Card.Body>
                    <div className="d-flex align-items-center">
                      <div className="">
                        <p className="mb-2 text-secondary">
                          Total Finance Deals
                        </p>
                        <div className="d-flex flex-wrap justify-content-start align-items-center">
                          <h5 className="mb-0 font-weight-bold">
                            {casesList.length}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </OverlayTrigger>
            <OverlayTrigger
              trigger="click"
              placement="top"
              overlay={totalRevenuePopover}
            >
              <Col md="4">
                <Card>
                  <Card.Body>
                    <div className="d-flex align-items-center">
                      <div className="">
                        <p className="mb-2 text-secondary">Total Incentives</p>
                        <div className="d-flex flex-wrap justify-content-start align-items-center">
                          <h5 className="mb-0 font-weight-bold">0</h5>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </OverlayTrigger>
            <OverlayTrigger
              trigger="click"
              placement="top"
              overlay={commissionPopover}
            >
              <Col md="4">
                <Card>
                  <Card.Body>
                    <div className="d-flex align-items-center">
                      <div className="">
                        <p className="mb-2 text-secondary">
                          Company Fixed Cost
                        </p>
                        <div className="d-flex flex-wrap justify-content-start align-items-center">
                          <h5 className="mb-0 font-weight-bold">0</h5>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </OverlayTrigger>
            <OverlayTrigger
              trigger="click"
              placement="top"
              overlay={unitSoldPopover}
            >
              <Col md="4">
                <Card>
                  <Card.Body>
                    <div className="d-flex align-items-center">
                      <div className="">
                        <p className="mb-2 text-secondary">Total Salary</p>
                        <div className="d-flex flex-wrap justify-content-start align-items-center">
                          <h5 className="mb-0 font-weight-bold">
                            {salary.toLocaleString("en-US")} AED
                          </h5>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </OverlayTrigger>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Top Deals</h4>
                  </div>
                </Card.Header>
                <Card.Body className="p-0">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="table-color-heading">
                        <tr className="text-secondary">
                          <th scope="col">Client Name</th>
                          <th scope="col">Deal Owner</th>
                          <th scope="col">Business Head</th>
                          <th scope="col">Deal Revenue</th>
                        </tr>
                      </thead>
                      <tbody>
                        {casesList &&
                          casesList.slice(0, 5).map((item) => (
                            <tr className="white-space-no-wrap">
                              <td>{item.cust_name} </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div>{item.dealOwner.name}</div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div>{item.buisnessHead.name}</div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <strong>
                                      {item.deal_revenue.toLocaleString(
                                        "en-US"
                                      )}{" "}
                                      AED
                                    </strong>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-end align-items-center border-top-table p-3">
                      <button className="btn btn-secondary btn-sm">
                        See All
                      </button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default DirectorDashbord;
