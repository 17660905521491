import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
  Badge,
} from "react-bootstrap";
import Card from "../../../../../components/Card";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import {
  getUsers,
  userDelete,
  getCase,
  getUser,
  getAllCase,
  getMonthlyCases,
} from "../../../../../actions/userAction/userAction";
import avatar from "../../../../../assets/images/avatar-3.png";
import { useDispatch, useSelector } from "react-redux";

const MyTeam = () => {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const userList = useSelector((state) => state.userReducer.users);
  const teamList = useSelector((state) => state.teamReducer.teams);
  const casesList = useSelector((state) => state.caseReducer.casesList);

  const [month, setMonth] = useState(moment().format("MM").toString());
  const [year, setYear] = useState(moment().format("YYYY").toString());
  console.log("🚀 ~ file: ListDeal.js:37 ~ MyTeam ~ month:", month);

  useEffect(() => {
    if (userData.role.name === "DIRECTOR") {
      dispatch(getAllCase());
    }
    // else {
    //   dispatch(getCase({ userId: userData.id }));
    // }
    dispatch(getUser());
  }, []);

  useEffect(() => {
    dispatch(
      getMonthlyCases({ userId: userData.id, month: month, year: "2023" })
    );
  }, [month]);

  const businessDate = [
    { name: "January", value: "01" },
    { name: "February", value: "02" },
    { name: "March", value: "03" },
    { name: "April", value: "04" },
    { name: "May", value: "05" },
    { name: "June", value: "06" },
    { name: "July", value: "07" },
    { name: "August", value: "08" },
    { name: "September", value: "09" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];

  const [q, setQ] = useState("");
  const [searchTerm] = useState(["email", "firstname", "lastname"]);

  const handleSearch = (items) => {
    return items.filter((item) => {
      return searchTerm.some((newItem) => {
        return (
          item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
  };

  return (
    <>
      {/* Add payment modal */}
      {/* <Modal
        show={showAddAmount}
        onHide={() => setShowAddAmount(false)}
        size="l"
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            onSubmit={(values) => {
              // values.user_id = customer.id;
              delete values.confirm_password;
              // dispatch(resetPassword(values));
              setShowAddAmount(false);
            }}
            // validationSchema={validationSchema}
            initialValues={{
              password: "",
              confirm_password: "",
            }}
          >
            {({ handleSubmit, handleChange, touched, errors, values }) => (
              <Form noValidate onSubmit={handleSubmit} id="change_password">
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Amount Recieved</Form.Label>
                      <Form.Control
                        type="number"
                        name="h_amount_recieved"
                        placeholder="Enter Amount"
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="confirm_password"
                        placeholder="Confirm Password"
                        onChange={handleChange}
                        isInvalid={!!errors.confirm_password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.confirm_password}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={() => setShowAddAmount(false)}>
            Close
          </Button>
          <Button variant="success" type="submit" form="change_password">
            Submit
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="font-weight-bold">Cases</h4>
              </div>
              <div className="create-workform">
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div className="modal-product-search d-flex">
                    <Form className="mr-3 position-relative">
                      <Form.Group className="mb-0">
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="exampleInputText"
                          placeholder="Search Cases"
                          value={q}
                          onChange={(e) => setQ(e.target.value)}
                        />
                        {/* <Link to="#" className="search-link">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className=""
                            width="20"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            />
                          </svg>
                        </Link> */}
                      </Form.Group>
                    </Form>
                    <Link
                      to={{
                        pathname: `/add-deal`,
                        state: "",
                      }}
                      className="btn btn-primary position-relative d-flex align-items-center justify-content-between"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2"
                        width="20"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                      </svg>
                      Add Cases
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <Row>
              <Col lg="12">
                <Card className="card-block card-stretch">
                  <Card.Body className="p-0">
                    <div className="d-flex justify-content-between align-items-center p-3">
                      <h5 className="font-weight-bold">Cases List</h5>
                      <Col md="3">
                        <select
                          // onSelect={() => setMonth(s)}
                          onChange={(e) => setMonth(e.target.value)}
                          // value={"May"}
                          className="form-select form-control mt-2"
                        >
                          <option>Select Month</option>
                          {businessDate.map((data) => {
                            return (
                              <option value={data.value}>{data.name}</option>
                            );
                          })}
                        </select>
                      </Col>
                    </div>
                    <div className="table-responsive">
                      <table className="table data-table mb-0">
                        <thead className="table-color-heading">
                          <tr className="">
                            <th scope="col">Customer Name</th>
                            <th scope="col">Deal Owner</th>
                            <th scope="col">Leader</th>
                            <th scope="col">PNL</th>
                            <th scope="col">Deal Revenue</th>
                            <th scope="col">Deal Quantam</th>
                            <th scope="col">Pending Payment</th>
                            <th scope="col" className="text-right">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {casesList.length > 0 &&
                            casesList.map((item) => {
                              const paymentStatus =
                                parseInt(item.h_quantam) -
                                parseInt(item.h_amount_recieved);
                              return (
                                <tr
                                  key={item.id}
                                  className="white-space-no-wrap"
                                >
                                  <td className="">
                                    <div className="active-project-1 d-flex align-items-center mt-0 ">
                                      <div className="h-avatar is-medium">
                                        <img
                                          className="avatar rounded-circle"
                                          alt="user-icon"
                                          src={item.image || avatar}
                                        />
                                      </div>
                                      <div className="data-content">
                                        <div>
                                          <span className="font-weight-bold">
                                            {item.cust_name}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    {userList.map((data) => {
                                      return (
                                        <>
                                          {item.deal_owner_id === data.id && (
                                            <>{data.name}</>
                                          )}
                                        </>
                                      );
                                    })}
                                  </td>
                                  <td>
                                    {userList.map((data) => {
                                      return (
                                        <>
                                          {item.leader_id === data.id && (
                                            <>{data.name}</>
                                          )}
                                        </>
                                      );
                                    })}
                                  </td>
                                  <td>
                                    {userList.map((data) => {
                                      return (
                                        <>
                                          {item.pnl_id === data.id && (
                                            <>{data.name}</>
                                          )}
                                        </>
                                      );
                                    })}
                                  </td>
                                  <td>
                                    <strong>
                                      {item.deal_revenue.toLocaleString(
                                        "en-US"
                                      )}{" "}
                                      AED
                                    </strong>
                                  </td>
                                  <td>
                                    <strong>
                                      {parseInt(item.h_quantam).toLocaleString(
                                        "en-US"
                                      )}{" "}
                                      AED
                                    </strong>
                                  </td>
                                  <td>
                                    <strong>
                                      {/* {paymentStatus == 0
                                        ? 
                                        : `${paymentStatus.toLocaleString(
                                            "en-US"
                                          )} AED`} */}
                                      <p
                                        className={`mb-0 ${
                                          paymentStatus == 0
                                            ? "text-success"
                                            : "text-danger"
                                        } d-flex justify-content-start align-items-center`}
                                      >
                                        <small>
                                          <svg
                                            className="mr-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <circle
                                              cx="12"
                                              cy="12"
                                              r="8"
                                              fill={
                                                paymentStatus == 0
                                                  ? "#3cb72c"
                                                  : "#f42b3d"
                                              }
                                            ></circle>
                                          </svg>
                                        </small>
                                        {paymentStatus == 0
                                          ? "Recieved"
                                          : `${paymentStatus.toLocaleString(
                                              "en-US"
                                            )} AED`}
                                      </p>
                                    </strong>
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-end align-items-center">
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>View</Tooltip>}
                                      >
                                        <Link
                                          className="mr-4"
                                          to={{
                                            pathname: `/view-deal`,
                                            state: { data: item },
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="text-secondary"
                                            width="20"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                            />
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                            />
                                          </svg>
                                        </Link>
                                      </OverlayTrigger>
                                      {/* <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip>Delete</Tooltip>}
                                    >
                                      <Button
                                        className="badge bg-danger"
                                        onClick={() =>
                                          handleShow(
                                            item.id,
                                            item.firstname,
                                            item.lastname
                                          )
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                          />
                                        </svg>
                                      </Button>
                                    </OverlayTrigger> */}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default MyTeam;
