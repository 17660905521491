import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  InputGroup,
} from "react-bootstrap";
import Card from "../../../../../components/Card";
import { Link, useParams, useLocation } from "react-router-dom";
// img
import avatar from "../../../../../assets/images/avatar-3.png";
import { useDispatch, useSelector } from "react-redux";
import Datepickers from "../../../../../components/Datepicker";

const ViewDeal = (props) => {
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const [account, setAccount] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [showDoc, setShowDoc] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showImagePreviewModal, setShowImagePreviewModal] = useState(false);

  const dispatch = useDispatch();

  const location = useLocation();
  const [customer, setCustomer] = useState(location.state.data);
  const [showImage, setShowImage] = useState("");
  const test = "";
  const [showStatusModal, setShowStatusModal] = useState(false);
  useEffect(() => {
    // dispatch(getAccount(customer.id));
    // dispatch(documentGet(customer.id));
  }, []);

  const openChangeStatusModal = () => {
    setShowStatusModal(true);
  };

  const handleClose = () => {
    setShowStatusModal(false);
    setShowImagePreviewModal(false);
  };

  const handleSubmit = () => {};

  const openImagePreview = (payload) => {
    setShowImage(payload);
    setShowImagePreviewModal(true);
  };

  return (
    <>
      <Modal show={showStatusModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">Update TCF Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{}}>
            <label>Change Status</label>
          </div>
          <select className="form-select form-control">
            <option>TCF Created(Pending)</option>
            <option>Not Counted (Confirmed)</option>
            {/* <option>Counted</option> */}
          </select>
          {/* <div className="form-group mb-3"> */}
          <div className="mt-4">
            <label htmlFor="">TCF Created Date</label>
            <Datepickers
              type="text"
              className="vanila-datepicker"
              name="birth_date"
              showFutureDates={true}
              placeholder="Enter Date"
              autoComplete="off"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" className="success" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showImagePreviewModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">View Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <img src={showImage} width="460" />
          </div>
        </Modal.Body>
      </Modal>
      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-between">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb p-0 mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/list-deal">Deals</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Deal Information
                    </li>
                  </ol>
                </nav>
              </div>
              <Link
                to="/customer"
                className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2">Back</span>
              </Link>
            </div>
          </Col>
          <Col lg="12" className="mb-3 d-flex justify-content-between">
            <h4 className="font-weight-bold d-flex align-items-center">
              Deal Information
            </h4>
          </Col>
        </Row>

        <Row>
          <Col lg="8">
            <Card>
              <ListGroup as="ul" className="list-group-flush">
                <ListGroup.Item as="li">
                  <div>
                    <ListGroup as="ul" className="list-style-1 mb-0">
                      <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-start align-items-center"
                      >
                        <div className="avatar">
                          <img
                            className="avatar avatar-img avatar-60 rounded-circle"
                            src={customer.image || avatar}
                            alt="01.jpg"
                          />
                        </div>
                        <div className="list-style-detail ml-4 mr-2">
                          <h5 className="font-weight-bold">
                            {customer.cust_name}
                          </h5>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <table className="table table-borderless mb-0">
                    <tbody>
                      {customer.deal_owner_id && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Deal Owner</p>
                          </td>
                          <td>
                            <p className="mb-0 ">{customer.dealOwner.name}</p>
                          </td>
                        </tr>
                      )}
                      {customer.leader_id && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Leader Name</p>
                          </td>
                          <td>
                            <p className="mb-0 ">{customer.leader.name}</p>
                          </td>
                        </tr>
                      )}

                      {customer.pnl_id && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">P&L Name</p>
                          </td>
                          <td>
                            <p className="mb-0 ">{customer.pnl.name}</p>
                          </td>
                        </tr>
                      )}

                      {customer.cust_name && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Customer Name</p>
                          </td>
                          <td>
                            <p className="mb-0 ">{customer.cust_name}</p>
                          </td>
                        </tr>
                      )}

                      {customer.cust_name && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Customer Name</p>
                          </td>
                          <td>
                            <p className="mb-0 ">{customer.cust_name}</p>
                          </td>
                        </tr>
                      )}

                      {customer.h_quantam && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Deal Quantam</p>
                          </td>
                          <td>
                            <h5 className="mb-0 text-success">
                              {customer.h_quantam.toLocaleString("en-US")} AED
                            </h5>
                          </td>
                        </tr>
                      )}

                      {customer.deal_revenue && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Deal Revenue</p>
                          </td>
                          <td>
                            <h5 className="mb-0 text-success">
                              {customer.deal_revenue.toLocaleString("en-US")}{" "}
                              AED
                            </h5>
                          </td>
                        </tr>
                      )}

                      {customer.cust_contact_no && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">
                              Customer Contact Number
                            </p>
                          </td>
                          <td>
                            <p className="mb-0 ">{customer.cust_contact_no}</p>
                          </td>
                        </tr>
                      )}

                      {customer.cust_email && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Customer Email</p>
                          </td>
                          <td>
                            <p className="mb-0 ">{customer.cust_email}</p>
                          </td>
                        </tr>
                      )}

                      {customer.cust_address && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Customer Address</p>
                          </td>
                          <td>
                            <p className="mb-0 ">{customer.cust_address}</p>
                          </td>
                        </tr>
                      )}

                      {/* {customer. && (
                      )} */}
                      {/* <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">
                            Attachment Of Soft Copy (KYC)
                          </p>
                        </td>
                        <td>
                          <p className="mb-0 "></p>
                        </td>
                      </tr> */}
                      {customer.leader_source && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Lead Source</p>
                          </td>
                          <td>
                            <p className="mb-0 ">{customer.leader_source}</p>
                          </td>
                        </tr>
                      )}

                      {customer.product_type && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Type</p>
                          </td>
                          <td>
                            <p className="mb-0 ">{customer.product_type}</p>
                          </td>
                        </tr>
                      )}

                      {customer.project_name && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Project Name</p>
                          </td>
                          <td>
                            <p className="mb-0 ">{customer.project_name}</p>
                          </td>
                        </tr>
                      )}

                      {customer.developer_name && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Developer Name</p>
                          </td>
                          <td>
                            <p className="mb-0 ">{customer.developer_name}</p>
                          </td>
                        </tr>
                      )}

                      {customer.unit_details && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Unit Details</p>
                          </td>
                          <td>
                            <p className="mb-0 ">{customer.unit_details}</p>
                          </td>
                        </tr>
                      )}

                      {customer.unit_price && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Unit Price</p>
                          </td>
                          <td>
                            <p className="mb-0 ">{customer.unit_price}</p>
                          </td>
                        </tr>
                      )}

                      {customer.down_payment && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Down Payment</p>
                          </td>
                          <td>
                            <p className="mb-0 ">{customer.down_payment}</p>
                          </td>
                        </tr>
                      )}

                      {customer.other_charges && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Other Charges</p>
                          </td>
                          <td>
                            <p className="mb-0 ">{customer.other_charges}</p>
                          </td>
                        </tr>
                      )}

                      {customer.payment_plan && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Payment Plan</p>
                          </td>
                          <td>
                            <p className="mb-0 "> {customer.payment_plan}</p>
                          </td>
                        </tr>
                      )}

                      {customer.incentive_type && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Incentive Type</p>
                          </td>
                          <td>
                            <p className="mb-0 ">{customer.incentive_type}</p>
                          </td>
                        </tr>
                      )}

                      {customer.commission_milestone && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">
                              Commision Milestone
                            </p>
                          </td>
                          <td>
                            <p className="mb-0 ">
                              {customer.commission_milestone}
                            </p>
                          </td>
                        </tr>
                      )}

                      {customer.h_amount_recieved && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Amount Recieved</p>
                          </td>
                          <td>
                            <p className="mb-0 ">
                              {customer.h_amount_recieved}
                            </p>
                          </td>
                        </tr>
                      )}
                      {customer.tcf_createDate && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Deal Create Date</p>
                          </td>
                          <td>
                            <p className="mb-0 ">{customer.tcf_createDate}</p>
                          </td>
                        </tr>
                      )}

                      {customer.passback && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Pass Back</p>
                          </td>
                          <td>
                            <p className="mb-0 ">{customer.passback}</p>
                          </td>
                        </tr>
                      )}

                      {customer.eoi && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">EOI</p>
                          </td>
                          <td>
                            <p className="mb-0 "> {customer.eoi}</p>
                          </td>
                        </tr>
                      )}

                      {customer.status && (
                        <tr>
                          <td className="p-0">
                            <p className="mb-0 text-muted">Case Status</p>
                          </td>
                          <td>
                            <p className="mb-0">{customer.status}</p>
                          </td>
                        </tr>
                      )}

                      {(customer.cust_emiratesId ||
                        customer.payment_proof_doc ||
                        customer.cust_visa ||
                        customer.cust_addressProof ||
                        customer.payment_proof_doc ||
                        customer.cut_kyc) && (
                        <tr>
                          <td className="p-0">
                            <h5 className="mb-0 text-muted">
                              Attachments Of Soft Copy
                            </h5>
                          </td>
                        </tr>
                      )}

                      {customer.cust_emiratesId && (
                        <tr>
                          <td>
                            <p className="mb-0 text-muted">Emmirates Id</p>
                          </td>
                          <td>
                            <div className="thumb-preview">
                              <img
                                src={customer.cust_emiratesId}
                                alt=""
                                onClick={() => {
                                  openImagePreview(customer.cust_emiratesId);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      )}
                      {customer.cust_passport !== "" && (
                        <tr>
                          <td>
                            <p className="mb-0 text-muted">Passport</p>
                          </td>
                          <td>
                            <div className="thumb-preview">
                              <img
                                src={customer.cust_passport}
                                alt=""
                                onClick={() => {
                                  openImagePreview(customer.cust_passports);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      )}
                      {customer.cust_addressProof && (
                        <tr>
                          <td>
                            <p className="mb-0 text-muted">Address Proof</p>
                          </td>
                          <td>
                            <div className="thumb-preview">
                              <img
                                src={customer.cust_addressProof}
                                alt=""
                                onClick={() => {
                                  openImagePreview(customer.cust_addressProof);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      )}
                      {customer.cust_visa && (
                        <tr>
                          <td>
                            <p className="mb-0 text-muted">Visa</p>
                          </td>
                          <td>
                            <div className="thumb-preview">
                              <img
                                src={customer.cust_visa}
                                alt=""
                                onClick={() => {
                                  openImagePreview(customer.cust_visa);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      )}
                      {customer.payment_proof_doc && (
                        <tr>
                          <td>
                            <p className="mb-0 text-muted">Payment Proof</p>
                          </td>
                          <td>
                            <div className="thumb-preview">
                              <img
                                src={customer.payment_proof_doc}
                                alt=""
                                onClick={() => {
                                  openImagePreview(customer.payment_proof_doc);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      )}
                      {customer.cut_kyc && (
                        <tr>
                          <td>
                            <p className="mb-0 text-muted">Kyc Proof</p>
                          </td>
                          <td>
                            <div className="thumb-preview">
                              <img
                                src={customer.cut_kyc}
                                alt=""
                                onClick={() => {
                                  openImagePreview(customer.cut_kyc);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
          <Col lg="4">
            <Card>
              <ListGroup.Item as="li">
                <Form>
                  <div style={{ display: "flex" }}>
                    <label>Status Update</label>
                    <Form.Check
                      type="switch"
                      label=""
                      className="ml-4"
                      style={{ fontSize: "40px" }}
                      id="disabled-custom-switch"
                      onClick={openChangeStatusModal}
                    />
                  </div>
                </Form>
              </ListGroup.Item>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ViewDeal;
