import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";

const CompanyFixedCost = () => {
  return (
    <>
      <Container fluid>
        <Row>Hello Company Fixed Cost</Row>
      </Container>
    </>
  );
};
export default CompanyFixedCost;
