import React, { useEffect, useState, useRef } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory, useLocation, Link } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Card from "../../../../../components/Card";

import { useDispatch, useSelector } from "react-redux";
import { addIncentives } from "../../../../../actions/AdminAction/adminAction";
import { getUser } from "../../../../../actions/userAction/userAction";

const AddIncentives = (props) => {
  // get customer data
  const location = useLocation();
  const [customer, setCustomer] = useState(location.state?.data);
  const isEdit = !!customer;

  const history = useHistory();
  useEffect(() => {
    dispatch(getUser());
  }, []);
  const validationSchema = Yup.object().shape({
    userId: Yup.string().required("Required"),
    year: Yup.string().required("Required"),
    amount: Yup.string().required("Required"),
    month: Yup.string().required("Required"),
  });

  const dispatch = useDispatch();
  const userList = useSelector((state) => state.userReducer.users);

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="d-flex flex-wrap align-items-center justify-content-end">
              <Link
                to="/"
                className="btn btn-primary btn-sm d-flex align-items-center justify-content-between"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2">Back</span>
              </Link>
            </div>
          </Col>
          <Col lg="12" className="mb-3 d-flex justify-content-between">
            <h4 className="font-weight-bold0 d-flex align-items-center">
              Add Incentives
            </h4>
          </Col>
          <Formik
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const payload = {
                month: values.month,
                year: values.year,
                amount: values.amount,
                userId: values.userId,
              };
              dispatch(addIncentives(payload));
            }}
            initialValues={{
              month: null,
              year: null,
              amount: null,
              userId: null,
            }}
          >
            {({ handleSubmit, handleChange, touched, errors, values }) => (
              <Col lg="12">
                <Card>
                  <Card.Body>
                    <Form noValidate onSubmit={handleSubmit}>
                      <Row>
                        <Col md="12">
                          <Card.Header>
                            <Card.Header.Title>
                              <h4 className="card-title">Add Incentives</h4>
                            </Card.Header.Title>
                          </Card.Header>
                          <Card.Body>
                            <div className="new-user-info">
                              <Row>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Month</label>
                                    <Field
                                      type="text"
                                      className={`form-control ${
                                        touched.dealOwner && errors.dealOwner
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Enter Month"
                                      name="month"
                                    />

                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="month"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Year</label>
                                    <Field
                                      type="text"
                                      className={`form-control ${
                                        touched.leaderName && errors.leaderName
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Enter Year"
                                      name="year"
                                    />

                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="year"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Incentives Amount</label>
                                    <Field
                                      type="text"
                                      className={`form-control ${
                                        touched.plname && errors.plname
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Enter Incentives Amount"
                                      name="amount"
                                    />

                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="amount"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Employee ID</label>
                                    <Field
                                      as="select"
                                      className={`form-control ${
                                        touched.customerName &&
                                        errors.customerName
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Enter Employee Id"
                                      name="userId"
                                    >
                                      <option disabled>Select Emp Id</option>
                                      {userList.map((data, index) => {
                                        return (
                                          <>
                                            <option value={data.id}>
                                              {data.id}
                                            </option>
                                          </>
                                        );
                                      })}
                                    </Field>
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="userId"
                                      component="span"
                                    />
                                  </div>
                                </div>
                              </Row>
                              <div className="d-flex justify-content-end mt-3">
                                <Button type="submit" variant="btn btn-primary">
                                  Add Incentives
                                </Button>
                              </div>
                            </div>
                          </Card.Body>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Formik>
        </Row>
      </Container>
    </>
  );
};

export default AddIncentives;
