import React, { useEffect } from "react";
//datepicker
import { Datepicker } from "vanillajs-datepicker";
import { DateRangePicker } from "vanillajs-datepicker";
import "../../node_modules/vanillajs-datepicker/dist/css/datepicker.min.css";
// import $ from "jquery";
import moment from "moment";

const Datepickers = (props) => {
  useEffect(() => {
    const datepickers = document.querySelectorAll(".vanila-datepicker");
    Array.from(datepickers, (elem) => {
      return new Datepicker(elem);
    });
    const daterangePickers = document.querySelectorAll(
      ".vanila-daterangepicker"
    );
    Array.from(daterangePickers, (elem) => {
      return new DateRangePicker(elem);
    });
  }, []);

  // useEffect(() => {
  //   console.log("pppppppp", props.showFutureDates);
  //   if (props.showFutureDates) {
  //     var date = new Date();
  //     var currentMonth = date.getMonth();
  //     var currentDate = date.getDate();
  //     var currentYear = date.getFullYear();
  //     // console.log(document.getElementById("datepicker").max);
  //     $("#datepicker").datepicker({
  //       maxDate: new Date(currentYear, currentMonth, currentDate),
  //     });
  //   }
  // }, []);
  // $(function () {
  //   var date = new Date();
  //   var currentMonth = date.getMonth();
  //   var currentDate = date.getDate();
  //   var currentYear = date.getFullYear();
  //   $("#datepicker").datepicker({
  //     maxDate: new Date(currentYear, currentMonth, currentDate),
  //   });
  // });
  return (
    <>
      <input
        type="text"
        name={props.names}
        className={`form-control ${props.className}`}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        // id={props.id}
        id="datepicker"
        maxDate={new Date()}
        value={props.value}
      />
    </>
  );
};

export default Datepickers;
